import React, { useState, useEffect } from "react";
import { Table, Button, Select, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useStateContext } from "../contexts/ContextProvider.jsx";

const { Option } = Select;
const { Search } = Input;

const CouponsTable = () => {
  const [couponsData, setCouponsData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const { saveIndex } = useStateContext();
  const { getCoupons } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        setLoading(true);
        const response = await getCoupons();
        setLoading(false);
        if (response) setCouponsData(response);
        else setAlertShown(true);
      } catch (error) {
        setLoading(false);
        setAlertShown(true);
        console.log("Error fetching coupon data:", error);
      }
    };

    fetchCoupons();
  }, []);

  const handleEditRowBtn = (id) => {
    saveIndex(id);
    navigate("/updateCoupons");
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setPage(1);
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const handleTypeFilter = (value) => {
    setTypeFilter(value);
  };

  const filteredData = couponsData.filter((item) => {
    if (typeFilter && item.type !== typeFilter) return false;
    if (searchTerm) {
      return (
        item.coupon_id.toString().includes(searchTerm) ||
        item.coupon_code.toLowerCase().includes(searchTerm)
      );
    }
    return true;
  });

  let columns = [
    { title: "Coupon ID", dataIndex: "coupon_id" },
    { title: "Coupon Code", dataIndex: "coupon_code" },
    { title: "Type", dataIndex: "type" },
    { title: "Start Date", dataIndex: "start_date" },
    { title: "Expire Date", dataIndex: "expire_date" },
    { title: "Max Discount Per Order", dataIndex: "max_disc_per_order" },
    { title: "Times Allowed", dataIndex: "times_allowed" },
    { title: "Minimum Cart Value", dataIndex: "min_cart_value" },
    { title: "Is For New User Only", dataIndex: "for_new_only" },
    {
      title: "Maximum Times Allowed Per User",
      dataIndex: "times_allowed_per_user",
    },
    { title: "User ID", dataIndex: "user_id" },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.coupon_id)}>Edit</Button>
      ),
    },
  ];

  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Update Coupons')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  return (
    <div className="m-2 p-2 md:p-10 bg-white rounded-3xl">
      <h1 className="text-3xl text-[#03c9d7] mb-4">Coupons Table</h1>
      <div style={{ marginBottom: "16px", marginTop: "25px" }}>
        <Select
          style={{ width: 120, marginRight: "16px" }}
          placeholder="Filter Type"
          onChange={handleTypeFilter}
          value={typeFilter}
        >
          <Option value="">All</Option>
          <Option value="absolute">Absolute</Option>
          <Option value="percent">Percent</Option>
        </Select>
        <Search
          placeholder="Search here"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: filteredData.length,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
        loading={loading}
      />
      <Modal
        title="Error"
        open={alertShown}
        closable={false}
        footer={[
          <Button
            key="enter"
            className="bg-[#03c9d7]"
            type="primary"
            onClick={() => {
              navigate("/Coupons");
              setAlertShown(false);
            }}
          >
            OK
          </Button>,
        ]}
        className="error-modal"
      >
        <p>Error fetching coupon data</p>
      </Modal>
    </div>
  );
};

export default CouponsTable;
