import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Select, Button, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useApiContext } from "../contexts/ApiProvider";
import { statuses, AdminEditField } from "../data/dummy";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { GiConsoleController } from "react-icons/gi";
import { Modal } from "antd";
const { Option } = Select;


const UpdateAdminForm = () => {
  const navigate = useNavigate();
  const { index } = useStateContext();
  const { getAdmin, updateAdmin ,getRoles } = useApiContext();
  const [AdminData, setAdminData] = useState([]);
  const [inputFieldVal, setInputFieldVal] = useState({});
  const [status, setStatus] = useState("Active");
  const [showPassword, setShowPassword] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({title:"Error",message:"Error updating admin data"});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAdmin();
        if(response)
          setAdminData(response);
        else
          setAlertShown(true);     
      } catch (error) {
        setAlertShown(true);
        console.log("Error fetching admin data:", error);
      }
    };

    fetchData();
  }, [getAdmin]);

  useEffect(() => {
    // Fetch roles data when the component mounts
    async function fetchRoles() {
      try {
        const response = await getRoles();
        if (response) {
          setRoles(response); // Assuming the roles data is in response.data.roles
        }
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchRoles(); // Call the function to fetch roles
  }, [getRoles]);

  useEffect(() => {
    const admin = AdminData.find((admin) => admin.id === index);
    if (admin) {
      console.log(admin)
      setInputFieldVal(admin);
      setStatus(admin.status);
    }
  }, [AdminData, index]);

  const handleEditInputChange = (val, editFieldName) => {
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val, status: "Active" });
  };

  const handleSetStatus = (val) => {
    setStatus(val);
    setInputFieldVal({ ...inputFieldVal, status: val });
  };

  const handleCancel = () => {
    // const originalAdmin = AdminData.find((admin) => admin.id === index);
    // setInputFieldVal(originalAdmin);
    // setStatus(originalAdmin.status);
    
    navigate("/Admin");
  };

  const handleSave = async () => {
    try {
      setLoading(true)
      const response = await updateAdmin(inputFieldVal);
      setLoading(false)
      if (response?.errors) {
        setMessage({title:"Error",message:response?.errors[0].msg})
        setAlertShown(true)
        console.log("Something went wrong", response?.errors[0].msg);
        return;
      }
      setMessage({title:"Success",message:"Admin data updated!!"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update Admin
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
          {AdminEditField.map((field, index) => (
  <div key={index} className="flex flex-col items-start">
    <label
      htmlFor={field.label}
      className="text-sm font-medium text-black"
    >
      {field.label}:
    </label>
    {field.value === "role" ? (
      <Select
        id="role"
        className="h-[50px] w-[1000px] mt-5 border-white text-black"
        value={inputFieldVal.role}
        onChange={(value) => handleEditInputChange(value, "role")}
        placeholder="Select Role"
      >
        {roles.map((role, index) => (
          <Option key={index} value={role.role}>
            {role.role}
          </Option>
        ))}
      </Select>
    ) : (
      <Input
        value={inputFieldVal[field.value] || ""}
        className="h-[50px] w-[1000px] mt-5 border-white text-black"
        onChange={(e) =>
          handleEditInputChange(e.target.value, field.value)
        }
        placeholder={`Enter Your ${field.label}`}
        prefix={
          field.value === "password" ? (
            <LockOutlined />
          ) : (
            <UserOutlined />
          )
        }
        type={
          field.value === "password"
            ? showPassword
              ? "text"
              : "password"
            : "text"
        }
        suffix={
          field.value === "password" && (
            <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <EyeTwoTone />
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
          )
        }
      />
    )}
  </div>
))}
            <div className="flex flex-col items-start">
              <label htmlFor="role" className="text-sm font-medium text-black">
                Role:
              </label>
              <Select
                id="role"
                className="h-[50px] w-[1000px] mt-5 border-white text-black"
                value={inputFieldVal.role}
                onChange={(value) => handleEditInputChange(value, "role")}
                placeholder="Select Role"
              >
                {roles.map((role, index) => (
                  <Option key={index} value={role.role}>
                    {role.role}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="items-start justify-start flex flex-col">
              <label>Status:</label>
              <Select
                placeholder="Select Status"
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={status}
                onChange={handleSetStatus}
              >
                {statuses.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Admin");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateAdminForm;
