import React, { useState, useEffect } from "react";
import { Table, Button, Input } from "antd";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const CartTable = () => {
  const { getCart } = useApiContext();
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameSortOrder, setNameSortOrder] = useState(null); // State for sorting by name
  const [alertShown, setAlertShown] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        setLoading(true);
        const response = await getCart();
        setLoading(false);
        if(response)
        setCartData(response);
        else setAlertShown(true)
        
      } catch (error) {
        setAlertShown(true)
        console.error("Error fetching cart data:", error);
        setLoading(false);
      }
    };

    fetchCartData();
  }, [getCart]);

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const handleNameSort = () => {
    setNameSortOrder((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };

  const filteredData = cartData.filter((item) => {
    return (
      item.name.toString().includes(searchTerm) ||
      item.product_name.toString().toLowerCase().includes(searchTerm) ||
      item.mobile.toString().includes(searchTerm) ||
      item.quantity.toString().includes(searchTerm) ||
      // item.first_name.toLowerCase().includes(searchTerm) || // Include first name in search
      // item.last_name.toLowerCase().includes(searchTerm) ||  // Include last name in search
      item.email.toLowerCase().includes(searchTerm)
    );
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const nameA = a.first_name.toUpperCase();
    const nameB = b.first_name.toUpperCase();
    return nameSortOrder === "ascend"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  let columns = [
    { title: "Customer Name", dataIndex: "name" },
    { title: "Mobile", dataIndex: "mobile" },
    { title: "Product Name", dataIndex: "product_name" },
    { title: "Quantity", dataIndex: "quantity" },
    // { title: "First Name", dataIndex: "first_name" },
    // { title: "Last Name", dataIndex: "last_name" },
    { title: "Email", dataIndex: "email" },
    { title: "Creation Date", dataIndex: "creation_date" },
  ];
  
    //Check access for edit button
    const permissions= sessionStorage.getItem('permissions');
    if (!permissions.includes('Update Cart')) {
      columns = columns.filter(column => column.title !== "Actions");
    }

  return (
    <div className="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
      <h1 className="text-3xl text-[#03c9d7] mb-4">Cart Table</h1>
      <div style={{ marginBottom: "16px", marginTop: "25px" }}>
        <Button onClick={handleNameSort}>Sort Name</Button>
        <Search
          placeholder="Search here"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200, marginLeft: 20 }}
        />
      </div>
      <Table
        dataSource={sortedData} // Use sortedData instead of cartData
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Cart");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching cart data</p>
        </Modal>
    </div>
  );
};

export default CartTable;
