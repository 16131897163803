import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import Box from "@mui/material/Box";
import { Input, Select, Button, message } from "antd";
import ImageUpload from "../components/ImageUpload";
import { useApiContext } from "../contexts/ApiProvider";
import { productsPopupEditField, statuses } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const { Option } = Select;

const UpdateProduct = () => {
  /* IMP: You need to include api here to use them */
  const [productsData, setProductsData] = useState([]);
  const [productEditData, setProductEditData] = useState([]);
  const { index } = useStateContext();
  const { getProducts } = useApiContext();
  const { updateProduct } = useApiContext();
  const navigate = useNavigate()
  const initialData = {
    statusIn: "",
    inputFieldValIn: "",
  };
  const [flag, setflag] = useState(true);
  const [status, setStatus] = useState(initialData.statusIn);
  const [inputFieldVal, setInputFieldVal] = useState(
    initialData.inputFieldValIn
  );
  const [file, setFile] = useState();
  const [logoAndImagesArr, setLogoAndImagesArr] = useState({ imgUrl: "" });
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({title:"Error",message:"Error updating product data"});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProducts();
        setProductsData(response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Ensure productsData has been fetched
    if (productsData.length > 0) {
      const productToEdit = productsData.find((prd) => prd.id === index);
      if (productToEdit && flag) {
        setInputFieldVal(productToEdit);
        setLogoAndImagesArr({
          logo: productToEdit.logo,
          image1: productToEdit.image1,
          image2: productToEdit.image2,
          image3: productToEdit.image3,
        });
        setflag(false);
        setStatus(productToEdit.status);
      }
    }
  }, [productsData, index, flag]);

  const handleEditInputChange = (val, editFieldName) => {
    // console.log(editFieldName)
    //console.log(val)
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val });
    //console.log(inputFieldVal)
  };

  const handleSetStatus = (val) => {
    setStatus(val);
    setInputFieldVal({ ...inputFieldVal, status: val });
  };
  //useEffect(() => { console.log(inputFieldVal) }, [inputFieldVal])

  const handleCancel = () => {
    // setStatus(status)
    // setInputFieldVal(inputFieldVal)
    navigate('/Products')
  };

  const handleSave = () => {
    console.log("save");
    updateProductApi();
  };

  const updateinputFieldVal = {
    ...inputFieldVal,
    productName: inputFieldVal.product_name,
    productDesc: inputFieldVal.product_desc,
    brandId: inputFieldVal.brand_id,
    productId: inputFieldVal.id,
    adminId: inputFieldVal.admin_id,
  };

  const updateProductApi = async () => {
    try {
      setLoading(true)
      const response = await updateProduct(updateinputFieldVal);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"Product data updated successfuly!!"})
      setAlertShown(true)
      setflag(true);
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };

  const handleImgChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update Product Form
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {productsPopupEditField.map((productEditFields, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={productEditFields.label}
                  className="text-sm font-medium text-black"
                >
                  {productEditFields.label}:
                </label>
                <Input
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  id="outlined-required"
                  value={inputFieldVal[productEditFields.value]}
                  onChange={(e) =>
                    handleEditInputChange(
                      e.target.value,
                      productEditFields.value
                    )
                  }
                  variant="standard"
                  label={productEditFields.label}
                  placeholder={`Enter Your ${productEditFields.label}`}
                />
              </div>
            ))}

            <div className="items-start justify-start flex flex-col">
              <label>Status:</label>
              <Select
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={status}
                placeholder="Select Status"
                onChange={handleSetStatus}
              >
                {statuses.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="mb-4 w-6/12">
              <label
                htmlFor="productName"
                className="text-sm font-medium text-black flex flex-col items-start"
              >
                Product Description:
              </label>
              <Input
                type="text"
                id="outlined-required"
                name="productName"
                value={inputFieldVal.product_desc}
                onChange={(e) =>
                  handleEditInputChange(e.target.value, "product_desc")
                }
                variant="standard"
                multiline
                className="h-[50px] w-[1000px] mt-5 border-white text-black"
                placeholder={`Enter Your Product Description`}
              />
            </div>

            <ImageUpload logoAndImages={logoAndImagesArr} />

            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Products");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateProduct;
