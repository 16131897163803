import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import { ApiContext, useApiContext } from "../contexts/ApiProvider";
import { couponsAddField, is_for_new_user, couponstatuses, couponTypes } from "../data/dummy";
import { Input, Select, Button, message } from "antd";
import { Modal } from "antd";
const { Option } = Select;

function AddProductForm({ handleTabClick }) {
  const initialData = {
    statusIn: "",
    inputFieldValIn: "",
  };

  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ title: "Error", message: "Error adding new Coupon " });
  const [status, setStatus] = useState(initialData.statusIn);
  const [formData, setFormData] = useState({
    couponCode: "",
    expiry_date: "",
    start_date: "",
    max_disc_per_order: "",
    times_allowed: "",
    counter: "",
    type: "",
    value: "",
    min_cart_value: "",
    isForNewUserOnly: "",
    times_allowed_per_user: "",
    user_id: "",
    status: "",
  });

  const { addCoupon } = useApiContext();

  const handleEditInputChange = (val, editFieldName) => {
    setFormData({ ...formData, [editFieldName]: val });
  };

  const handleSetStatus = (val) => {
    setStatus(val === "Active" ? "Active" : "Inactive");
    setFormData({ ...formData, status: val });
  };

  const handleSetIsForNewUser = (val) => {
    formData.isForNewUserOnly = val === "Y" ? "Y" : "N";
    setFormData({ ...formData, isForNewUserOnly: val });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await addCoupon(formData);
      setLoading(false);
      if (!response || response.errors) {
        let errorMessage = "Something went wrong";
        if (response.errors && response.errors.length > 0) {
          errorMessage = response.errors.map((error) => error.msg).join(", ");
        }
        setMessage({ title: "Error", message: errorMessage });
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Successfully added coupon" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center ">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Add Coupon
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="product-info flex flex-col flex-wrap justify-between">
                {couponsAddField.map((couponEditField, index) => (
                  <div key={index} className="flex flex-col items-start">
                    <label htmlFor={couponEditField.label} className="text-sm font-medium text-black mt-5">
                      {couponEditField.label}:
                    </label>
                    <Input
                      className="h-[50px] w-[1000px] mt-5 border-white text-black"
                      id="outlined-required"
                      onChange={(e) => handleEditInputChange(e.target.value, couponEditField.value)}
                      variant="standard"
                      label={couponEditField.label}
                      placeholder={`Enter Your ${couponEditField.label}`}
                    />
                  </div>
                ))}
              </div>

              <div className="items-start justify-start flex flex-col">
                <label>Status:</label>
                <Select
                  className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                  value={status}
                  placeholder="Select Status"
                  onChange={handleSetStatus}
                >
                  {couponstatuses.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="items-start justify-start flex flex-col">
                <label>Type:</label>
                <Select
                  className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                  value={formData.type}
                  placeholder="Select Type"
                  onChange={(val) => setFormData({ ...formData, type: val })}
                >
                  {couponTypes.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="items-start justify-start flex flex-col">
                <br />
                <label>For new user only?:</label>
                <Select
                  className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                  value={formData.isForNewUserOnly}
                  placeholder="Select Status"
                  onChange={handleSetIsForNewUser}
                >
                  {is_for_new_user.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="flex justify-center space-x-4 mt-5">
                <Button
                  type="primary"
                  className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  SAVE
                </Button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddProductForm;
