import React, { createContext, useContext } from "react";
import Cookies from "js-cookie";
export const ApiContext = createContext();
const backendHost = "https://api.sfoly.com";

export const ApiProvider = ({ children }) => {
  const fetchData = async (url, options, checkAuth = true) => {
    try {
      if (checkAuth) {
        const jwt = Cookies.get("jwt");
        const email = Cookies.get("adminId");
  
        if (!jwt || !email) {
          throw new Error("JWT token or adminId not found in cookies");
        }
  
        const headers = {
          ...options.headers,
          jwt: `${jwt}`,
          adminId: email,
        };
  
        options = { ...options, headers };
      }
  
      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("Error fetching data");
    }
  };
  

  const adminLogin = async (email, password) => {
    const url = backendHost + "/adminLogin";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    };
    const data = await fetchData(url, options, false); // Pass false to skip authentication check
    return data;
  };
  

  const addAdmin = async (AdminData) => {
    const url = backendHost + "/addAdmin";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AdminData),
    };
    return fetchData(url, options);
  };

  const addContact = async (ContactData) => {
    const url = backendHost + "/addContact";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(ContactData),
    };
    return fetchData(url, options);
  };

  const addDM = async (DmData) => {
    const url = backendHost + "/addDM";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(DmData),
    };
    return fetchData(url, options);
  };

  const addBanner = async (bannerdata) => {
    const url = backendHost + "/addBanner";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bannerdata),
    };
    return fetchData(url, options);
  };

  const addStore = async (storeData) => {
    const url = backendHost + "/addStore";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storeData),
    };
    return fetchData(url, options);
  };

  const sendPhoneOTP = async (phone) => {
    const url = backendHost + "/sendPhoneOTP";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone }),
    };
    return fetchData(url, options);
  };

  const verifyPhoneOTP = async (phone, otp) => {
    const url = backendHost + "/verifyPhoneOTP";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone, otp }),
    };
    return fetchData(url, options);
  };

  const createAccount = async (phone, firstName, email, lastName) => {
    const url = backendHost + "/createAccount";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone, firstName, email, lastName }),
    };
    return fetchData(url, options);
  };

  const getProductList = async (lat1, long1, lat2, long2) => {
    const url = backendHost + "/getProductList";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lat1, long1, lat2, long2 }),
    };
    return fetchData(url, options);
  };

  const addProduct = async (productData) => {
    const url = backendHost + "/addProduct";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productData),
    };
    return fetchData(url, options);
  };

  const addInventory = async (inventoryData) => {
    const url = backendHost + "/addInventory";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inventoryData),
    };
    return fetchData(url, options);
  };

  const getStore = async () => {
    const url = backendHost + "/getStores";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getBanners = async () => {
    const url = backendHost + "/getBanners";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };
  const getInventory = async () => {
    const url = backendHost + "/getInventory";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };
  const getStoreId = async (val) => {
    const url = backendHost + `/getInventory?storeId=${val}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getCoupons = async () => {
    const url = backendHost + "/getCoupons";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getContact = async () => {
    const url = backendHost + "/getContacts";
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetchData(url, options);
  };
  const getProducts = async () => {
    const url = backendHost + "/getProducts";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const updateProduct = async (productData) => {
    const url = backendHost + "/updateProduct";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productData),
    };
    return fetchData(url, options);
  };
  const updateContact = async (ContactData) => {
    const url = backendHost + "/updateContacts";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ContactData),
    };
    return fetchData(url, options);
  };
  const updateCoupon = async (CouponData) => {
    const url = backendHost + "/updateCoupon";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CouponData),
    };
    return fetchData(url, options);
  };
  const addCoupon = async (CouponData) => {
    const url = backendHost + "/addCoupon";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CouponData),
    };
    return fetchData(url, options);
  };
  const getAdmin = async () => {
    const url = backendHost + "/getAdmin";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };
  const getCustomer = async () => {
    const url = backendHost + "/getCustomer";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };
  const getAllOrders = async () => {
    const url = backendHost + "/getAllOrders";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getAllDm = async () => {
    const url = backendHost + "/getAllDm";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getCart = async () => {
    const url = backendHost + "/getCart";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };  

  const getRoles = async () => {
    const url = backendHost + "/getRoles";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  }; 

  const getProductDetails = async (orderId) => {
    const url = `${backendHost}/getProductDetails`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Append orderId to the URL
    const requestUrl = `${url}?orderId=${orderId}`;
    return fetchData(requestUrl, options);
  };
  

  const getCategories = async () => {
    const url = backendHost + "/getCategories";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getSubCategories = async () => {
    const url = backendHost + "/getSubCategories";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const getBrands = async () => {
    const url = backendHost + "/getBrands";
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetchData(url, options);
  };

  const addBrand = async (BrandData) => {
    const url = backendHost + "/addBrand";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BrandData),
    };
    return fetchData(url, options);
  };

  const addRole = async (RoleData) => {
    const url = backendHost + "/addRole";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(RoleData),
    };
    return fetchData(url, options);
  };

  const updateAdmin = async (AdminData) => {
    const url = backendHost + "/updateAdmin";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AdminData),
    };
    return fetchData(url, options);
  };
  const updateCustomer = async (CustomerData) => {
    const url = backendHost + "/updateCustomer";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CustomerData),
    };
    return fetchData(url, options);
  };

  const updateDm = async (DmData) => {
    const url = backendHost + "/updateDm";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(DmData),
    };
    return fetchData(url, options);
  };
  const updateOrder = async (OrderData) => {
    const url = backendHost + "/updateOrder";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(OrderData),
    };
    return fetchData(url, options);
  };
  const updateBrand = async (BrandData) => {
    const url = backendHost + "/updateBrand";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BrandData),
    };
    return fetchData(url, options);
  };

  const updateBanner= async (BannerData) => {
    const url = backendHost + "/updateBanner";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BannerData),
    };
    return fetchData(url, options);
  };

  const updateStore = async (storeData) => {
    const url = backendHost + "/updateStore";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storeData),
    };
    return fetchData(url, options);
  };

  const updateInventory = async (storeData) => {
    const url = backendHost + "/updateInventory";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storeData),
    };
    return fetchData(url, options);
  };
  
  const updateRole = async (RoleData) => {
    const url = backendHost + "/updateRole";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(RoleData),
    };
    return fetchData(url, options);
  };

  const assignDm = async (dmId,orderId,adminId) => {
    const url = backendHost + "/assignDm";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dmId, adminId, orderId }),
    };
    return fetchData(url, options);
  };

  return (
    <ApiContext.Provider
      value={{
        adminLogin,
        addAdmin,
        addDM,
        addRole,
        addContact,
        addStore,
        sendPhoneOTP,
        verifyPhoneOTP,
        createAccount,
        getProductList,
        addProduct,
        addInventory,
        getCustomer,
        getAllOrders,
        getProductDetails,
        getAllDm,
        getCart,
        getRoles,
        getBanners,
        getInventory,
        getAdmin,
        getStore,
        getContact,
        getStoreId,
        getCoupons,
        getProducts,
        updateProduct,
        getCategories,
        getSubCategories,
        getBrands,
        updateCoupon,
        addCoupon,
        addBrand,
        addBanner,
        updateDm,
        updateContact,
        updateCustomer,
        updateAdmin,
        updateBrand,
        updateOrder,
        updateStore,
        updateBanner,
        updateInventory,
        updateRole,
        assignDm
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApiContext = () => useContext(ApiContext);
