import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Select, Button, message } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { couponEditFields, couponstatuses, is_for_new_user, couponTypes } from "../data/dummy"; // Import necessary constants
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
const { Option } = Select;

const UpdateCoupons = () => {
  const navigate = useNavigate();
  const [inputFieldVal, setInputFieldVal] = useState({});

  const { getCoupons, updateCoupon } = useApiContext();
  const { index } = useStateContext();
  const [couponsData, setCouponsData] = useState([]);
  const [status, setStatus] = useState("Active");
  const [type, setType] = useState("absolute"); // State for 'type'
  const [isForNewUser, setIsForNewUser] = useState("N"); // State for 'isForNewUserOnly'
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ title: "Error", message: "Error updating Contact data" });
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCoupons();
        if(response)
          setCouponsData(response);
        else
          setAlertShown(true);     
      } catch (error) {
        setAlertShown(true);
        console.log("Error fetching coupon data:", error);
      }
    };

    fetchData();
  }, [getCoupons]);

  useEffect(() => {
    
    const coupon = couponsData.find((coupon) => coupon.coupon_id === index);
    if (coupon) {
      console.log(coupon.type)
      setInputFieldVal(coupon);
      setStatus(coupon.status);
      setType(coupon.type);
      setIsForNewUser(coupon.for_new_only)

    }
  }, [couponsData, index]);

  const handleEditInputChange = (val, editFieldName) => {
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val });
  };

  const handleCancel = () => {
    navigate('/Coupons');
  };

  const handleSave = () => {
    setLoading(true);
    updateCouponApi();
    setLoading(false);
  };

  const updateCouponApi = async () => {
    try {
      const response = await updateCoupon({
        ...inputFieldVal,
        status: status,
        type: type, // Include 'type' in the request
        isForNewUserOnly: isForNewUser // Include 'isForNewUserOnly' in the request
      });
      if (response?.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Updated coupon data successfully!!" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };



  const handleSetStatus = (val) => {
    setStatus(val === "Active" ? "Active" : "Inactive");
    setInputFieldVal({ ...inputFieldVal, status: val });
  };

  const handleSetType = (val) => {
    setType(val);
  };

  const handleSetIsForNewUser = (val) => {
    setIsForNewUser(val);
  };


  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update Coupons
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {couponEditFields.map((couponEditField, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={couponEditField.label}
                  className="text-sm font-medium text-black"
                >
                  {couponEditField.label}:
                </label>
                <Input
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  id="outlined-required"
                  value={inputFieldVal[couponEditField.value]}
                  onChange={(e) =>
                    handleEditInputChange(e.target.value, couponEditField.value)
                  }
                  variant="standard"
                  label={couponEditField.label}
                  placeholder={`Enter Your ${couponEditField.label}`}
                />
              </div>
            ))}

            <div className="items-start justify-start flex flex-col">
              <label>Status:</label>
              <Select
                placeholder="Select Status"
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={status}
                onChange={handleSetStatus}
              >
                {couponstatuses.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="items-start justify-start flex flex-col">
              <label>Type:</label>
              <Select
                placeholder="Select Type"
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={type}
                onChange={handleSetType}
              >
                {/* Use the options from the dummy data */}
                {couponTypes.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="items-start justify-start flex flex-col">
              <br />
              <label>For new user only?:</label>
              <Select
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={isForNewUser}
                placeholder="Select Status"
                onChange={handleSetIsForNewUser}
              >
                {is_for_new_user.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Coupons");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateCoupons;
