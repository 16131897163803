import React, { useState } from "react";
import AddAdminForm from "../components/AddAdminForm";
import AdminTable from "../components/AdminTable";
// import { Header } from "../components";

const Admin = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabs = [
    {
      title: "Admin",
      content: <AdminTable />,
    },
    {
      title: "Add",
      content: <AddAdminForm  handleTabClick ={handleTabClick}/>,
    },
  ];



  return (
    <div>
      <div className="tab-bar flex mt-24 ml-5 items-center md:mt-5">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`cursor-pointer text-center text-white w-36 ml-5 gap-2 py-2 rounded-md transition duration-300 ${
              activeTab === index
                ? "bg-gradient-to-br from-[#03c9d7] to-[#03c9d7] shadow-md"
                : "bg-white text-gray-400 hover:bg-white border border-[#03c9d7]"
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Admin;
