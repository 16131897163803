import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Select, Button, message } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";

const { Option } = Select;

const UpdateInventory = () => {
  const navigate = useNavigate();
  const { index , index2 } = useStateContext();
  const { getInventory, updateInventory } = useApiContext();
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [message, setMessage] = useState({
    title: "Error",
    message: "Error updating inventory data",
  });
  const [inputFieldVal, setInputFieldVal] = useState({
    storeId: "",
    productId: "",
    quantity: "",
    mode: "", // Changed to use a Select component
  });

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await getInventory();
        if (response) {
          setInventoryData(response);
        } else {
          setAlertShown(true);
        }
      } catch (error) {
        setAlertShown(true);
        console.error("Error fetching inventory data:", error);
      }
    };

    fetchInventory();
  }, []);

  useEffect(() => {
    // Ensure inventoryData has been fetched
    if (inventoryData.length > 0) {
      const inventoryToEdit = inventoryData.find(
        (item) => item.store_id === index && item.product_id === index2
      );
      if (inventoryToEdit) {
        // Select only the fields you want to display
        const { store_id, product_id, quantity, mode } = inventoryToEdit;
        setInputFieldVal({ storeId:store_id, productId:product_id, quantity, mode });
      }
    }
  }, [inventoryData, index, index2]);
  

  const handleEditInputChange = (val, fieldName) => {
    setInputFieldVal({ ...inputFieldVal, [fieldName]: val });
  };

  const handleCancel = () => {
    navigate("/Inventory");
  };

  const handleSave = async () => {
    setLoading(true);
    await updateInventoryApi();
    setLoading(false);
  };

  const updateInventoryApi = async () => {
    try {
      const response = await updateInventory(inputFieldVal);
      if (response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      } else
        setMessage({
          title: "Success",
          message: "Inventory data updated successfully!!",
        });
      setAlertShown(true);
    } catch (err) {
      console.log("Some error occurred", err);
      setAlertShown(true);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Update Inventory
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {Object.keys(inputFieldVal).map((fieldName, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={fieldName}
                  className="text-sm font-medium text-black mt-5"
                >
                  {fieldName}:
                </label>
                {fieldName === "mode" ? (
                  <Select
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    id={fieldName}
                    value={inputFieldVal[fieldName]}
                    onChange={(val) =>
                      handleEditInputChange(val, fieldName)
                    }
                  >
                    <Option value="add">Add</Option>
                    <Option value="subtract">Subtract</Option>
                    <Option value="delete">Delete</Option>
                  </Select>
                ) : (
                  <Input
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    id={fieldName}
                    value={inputFieldVal[fieldName]}
                    onChange={(e) =>
                      handleEditInputChange(e.target.value, fieldName)
                    }
                    variant="standard"
                    label={fieldName}
                    placeholder={`Enter Your ${fieldName}`}
                  />
                )}
              </div>
            ))}
            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Inventory");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateInventory;
