import React, { useState } from "react";
import { Input, Select, Button, Modal } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { inventoriesAddEditField } from "../data/dummy";

const { Option } = Select;

function AddInventoryForm({ handleTabClick }) {
  const { addInventory } = useApiContext();
  const [formData, setFormData] = useState({
    storeId: "",
    productId: "",
    quantityLeft: "",
    totalQuantity: "",
  });
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [message, setMessage] = useState({
    title: "Error",
    message: "Error adding new inventory",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await addInventory(formData);
      setLoading(false);
      if (!response || response.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Successfully added inventory" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Add Inventory
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="product-info flex flex-col flex-wrap justify-between">
                {inventoriesAddEditField.map((field, index) => (
                  <div key={index} className="flex flex-col items-start">
                    <label
                      htmlFor={field.label}
                      className="text-sm font-medium text-black mt-5"
                    >
                      {field.label}:
                    </label>
                    <Input
                      id={field.value}
                      className="h-[50px] w-[1000px] mt-5 border-white text-black"
                      value={formData[field.value]}
                      onChange={handleChange}
                      name={field.value}
                      placeholder={`Enter ${field.label}`}
                    />
                  </div>
                ))}
              </div>
              <div className="flex justify-center space-x-4 mt-5">
                <Button
                  type="primary"
                  className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddInventoryForm;
