import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Select, Button, message } from "antd";
import ImageUpload from "../components/ImageUpload";
import { useApiContext } from "../contexts/ApiProvider";
import {
  productsPopupEditField,
  storestatuses,
  storeEditField,
  storesAddEditField,
} from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { GiConsoleController } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
const { Option } = Select;

const UpdateStore = () => {
  const navigate = useNavigate();
  /* IMP: You need to include api here to use them */
  const [storesData, setstoresData] = useState([]);
  const [productEditData, setProductEditData] = useState([]);
  const { index } = useStateContext();
  const { getStore } = useApiContext();
  const { updateStore } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error updating store data"});

  const initialData = {
    statusIn: "",
    inputFieldValIn: "",
  };
  const [flag, setflag] = useState(true);
  const [status, setStatus] = useState(initialData.statusIn);
  const [inputFieldVal, setInputFieldVal] = useState(
    initialData.inputFieldValIn
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStore();
        console.log(response); // Check response data
        setstoresData(response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [getStore]);

  useEffect(() => {
    // Ensure storesData has been fetched
    console.log("step 1");
    if (storesData.length > 0) {
      console.log("step 2");
      const storeToEdit = storesData.find((str) => str.store_id === index);
      if (storeToEdit && flag) {
        console.log("step 3");
        setInputFieldVal(storeToEdit);
        setflag(false);
        setStatus(storeToEdit.status);
      }
    }
  }, [storesData, index, flag]);

  const handleEditInputChange = (val, editFieldName) => {
    // console.log(editFieldName)
    //console.log(val)
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val });
    //console.log(inputFieldVal)
  };

  const handleSetStatus = (val) => {
    //console.log(val)
    setStatus(val === "Active" ? "Active" : "Inactive");
    setInputFieldVal({ ...inputFieldVal, status: val });
    console.log(inputFieldVal);
    //4console.log(inputFieldVal)
  };

  //useEffect(() => { console.log(inputFieldVal) }, [inputFieldVal])

  const handleCancel = () => {
    // setStatus(status)
    // setInputFieldVal(inputFieldVal)
    navigate('/stores')
  };

  const handleSave = async () => {
    console.log("save");
    await updateStoreApi();
  };

  const updatedInputFieldVal = {
    ...inputFieldVal,
    street: inputFieldVal.street_address,
    pin: inputFieldVal.pincode,
    adminId: inputFieldVal.admin_id,
    storeId: inputFieldVal.store_id,
  };
  const updateStoreApi = async () => {
    try {
      setLoading(true)
      const response = await updateStore(updatedInputFieldVal);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"successfully updated store data"})
      setAlertShown(true)
      setflag(true);
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update Store
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {storeEditField.map((storeEditFields, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={storeEditFields.label}
                  className="text-sm font-medium text-black"
                >
                  {storeEditFields.label}:
                </label>
                <Input
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  id="outlined-required"
                  value={inputFieldVal[storeEditFields.value]}
                  onChange={(e) =>
                    handleEditInputChange(e.target.value, storeEditFields.value)
                  }
                  variant="standard"
                  label={storeEditFields.label}
                  placeholder={`Enter Your ${storeEditFields.label}`}
                />
              </div>
            ))}

            <div className="items-start justify-start flex flex-col">
              <label>Status:</label>
              <Select
                placeholder="Select Status"
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={status}
                onChange={handleSetStatus}
              >
                {storestatuses.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Stores");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateStore;
