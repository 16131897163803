import React, { useState, useEffect } from "react";
import { Table, Button, Select, Input, Modal } from "antd";
import axios from "axios";
import { ApiContext, useApiContext } from "../contexts/ApiProvider";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Search } = Input;

const BannerTable = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameSortOrder, setNameSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [alertShown, setAlertShown] = useState(false);
  const { getBanners } = useApiContext();
  const { saveIndex } = useStateContext();
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true; // Add a flag to track component mount status
  
    const fetchBanners = async () => {
      try {
        setLoading(true);
        const response = await getBanners();
        if (isMounted) { // Check if the component is still mounted before updating state
          setBanners(response);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlertShown(true);
        console.log("Error fetching banner data:", error);
      }
    };
  
    fetchBanners();
  
    return () => {
      isMounted = false; // Set the flag to false when component is unmounted
    };
  }, []);
  

  const handlePageChange = (pageNumber) => {
    // Handle pagination change if needed
  };

    const handleEditRowBtn = (index) => {
      console.log("Editing banner with ID:", index);
      saveIndex(index);
    navigate("/updateBannerForm");
    };
  const handlePageSizeChange = (current, size) => {
    // Handle page size change if needed
  };

  const handleNameSort = () => {
    setNameSortOrder((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = banners ? banners.filter((banner) => {
    
    if (searchTerm) {
      return (
        banner.banner_id.toString().includes(searchTerm) ||
        banner.title.toLowerCase().includes(searchTerm)
      );
    }
    return true;
  }) : [];
  

  const sortedData = filteredData.slice().sort((a, b) => {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();
    return nameSortOrder === "ascend"
      ? titleA.localeCompare(titleB)
      : titleB.localeCompare(titleA);
  });
  

  let columns = [
    { title: "Banner ID", dataIndex: "banner_id" },
    { title: "Title", dataIndex: "title" },
    { title: "Desktop Image", dataIndex: "desktop_img" },
    { title: "Mobile Image", dataIndex: "mobile_img" },
    { title: "App Link", dataIndex: "app_link" },
    { title: "Web Link", dataIndex: "web_link" },
    { title: "Image Alt", dataIndex: "img_alt" },
    { title: "Image Title", dataIndex: "img_title" },
    { title: "Status", dataIndex: "status" },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.banner_id)}>Edit</Button>
      ),
    },
  ];
  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Update Banners')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  
  

  return (
    <div className="flex">
      <div className="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 className="text-3xl text-[#03c9d7] mb-4">Banner Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <Button onClick={handleNameSort}>Sort Name</Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 800 }}
          />
        </div>
        <Table
  dataSource={sortedData.map((item, index) => ({
    ...item,
    key: index,
  }))}
  columns={columns}
  pagination={{
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: ["5", "10", "20", "50"],
    total: filteredData.length,
  }}
  loading={loading}
  scroll={{ x: true }} // Enable horizontal scrolling
/>

      </div>
      <Modal
        title="Error"
        open={alertShown}
        footer={[
          <Button
            key="enter"
            className="bg-[#03c9d7]"
            type="primary"
            onClick={() => setAlertShown(false)}
          >
            OK
          </Button>,
        ]}
        onCancel={() => setAlertShown(false)}
      >
        <p>Error fetching banner data</p>
      </Modal>
    </div>
  );
};

export default BannerTable;
