import { Navigate } from "react-router-dom";
import { useStateContext } from '../contexts/ContextProvider';

export const PrivateRoute = ({ children }) => {
    const { userLoggedIn } = useStateContext();
    if (!userLoggedIn) {
    // user is not authenticated
    return <Navigate to="/signin" />;
  }
  return children;
};