import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Button, Modal ,Select } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { AddBannerField,bannerstatuses } from "../data/dummy";
const { Option } = Select;

const UpdateBannerForm = () => {
  const navigate = useNavigate();
  const { getBanners, updateBanner } = useApiContext();
  const [status, setStatus] = useState("Active");
  const [bannerData, setBannerData] = useState([]);
  const { index } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [message, setMessage] = useState({ title: "Error", message: "Error updating banner data" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBanners();
        if (response) {
          setBannerData(response);
        } else {
          setAlertShown(true);
        }
      } catch (error) {
        setAlertShown(true);
        console.error("Error fetching banners:", error);
      }
    };

    fetchData();
}, []);


  useEffect(() => {
    if (bannerData.length > 0) {
      const bannerToEdit = bannerData.find((banner) => banner.banner_id === index);
      console.log(bannerToEdit)
      if (bannerToEdit) {
        setBannerData(bannerToEdit);
        setStatus(bannerToEdit.status);
      }
    }
    console.log(bannerData);
  }, [bannerData, index]);

  const handleInputChange = (e, field) => {
    setBannerData({ ...bannerData, [field]: e.target.value });
  };

  const handleCancel = () => {
    navigate('/Banners');
  };

  const handleSetStatus = (val) => {
    setStatus(val);
    setBannerData({ ...bannerData, status: val });
  };

  const handleSave = async () => {
    await updateBannerApi();
  };

  const updateBannerApi = async () => {
    try {
      setLoading(true);
      const response = await updateBanner(bannerData);
      setLoading(false);
      if (!response||response?.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Banner data updated successfully!!" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Update Banner
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {AddBannerField.map((field, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={field.value}
                  className="text-sm font-medium text-black mt-5"
                >
                  {field.label}:
                </label>

                <Input
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  id={field.value}
                  value={bannerData[field.value]}
                  onChange={(e) =>
                    handleInputChange(e, field.value)
                  }
                  variant="standard"
                  label={field.label}
                  placeholder={`Enter Your ${field.label}`}
                />

              </div>
            ))}
            <div className="items-start justify-start flex flex-col">
              <label>Status:</label>
              <Select
                placeholder="Select Status"
                className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                value={status}
                onChange={handleSetStatus}
              >
                {bannerstatuses.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Banners");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateBannerForm;
