import React, { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [userLoggedIn, setIsUserLoggedIn] = useState(
    sessionStorage.getItem("token")
  );
  const [adminName, setAdminName] = useState(
    sessionStorage.getItem("adminName")
  );
  const [userProfile, setUserProfile] = useState(true);
  const [index, setIndex] = useState(
    sessionStorage.getItem("productIndex") || 1
  );
  const [index2, setIndex2] = useState(
    sessionStorage.getItem("productIndex2") || 1
  );

  const isUserLoggedIn = () => {
    setIsUserLoggedIn(sessionStorage.getItem("token"));
  };

  const signInSuccessful = () => {
    sessionStorage.setItem("token", true);
    setIsUserLoggedIn(true);
  };

  const handleSetAdminName = (adminName) => {
    setAdminName(adminName);
    sessionStorage.setItem("adminName", adminName);
  };

  const handleAdminLogout = () => {
    setIsUserLoggedIn(false);
    setActiveMenu(false);
    sessionStorage.setItem("token", false);
    sessionStorage.clear();
    setIsClicked(initialState);
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    sessionStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    sessionStorage.setItem("colorMode", color);
  };

  useEffect(() => {
    console.log("From context:", index);
  }, [index]);
  const saveIndex = (e) => {
    setIndex(e);

    sessionStorage.setItem("productIndex", e);
  };
  useEffect(() => {
    console.log("From context:", index2);
  }, [index2]);
  const saveIndex2 = (e) => {
    setIndex2(e);

    sessionStorage.setItem("productIndex2", e);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        index,
        saveIndex,
        index2,
        saveIndex2,
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        isUserLoggedIn,
        signInSuccessful,
        userLoggedIn,
        handleSetAdminName,
        adminName,
        handleAdminLogout,
        setUserProfile,
        userProfile,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
