import React, { useState, useRef } from "react";
import { Input, Select, Button, Modal } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useApiContext } from "../contexts/ApiProvider";
import { AddCustomerField } from "../data/dummy"; // Importing customer fields
import { useNavigate } from "react-router-dom";

const { Option } = Select;

function AddCustomerForm({ handleTabClick }) {
  const initialData = {
    statusIn: "",
    inputFieldValIn: "",
  };

  const { addCustomer } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    // Initialize form data according to customer columns
    id: "",
    mobile: "",
    firstName: "",
    lastName: "",
    email: "",
    creationDate: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState(initialData.statusIn);
  const [alertShown, setAlertShown] = useState(false);
  const [message, setMessage] = useState({
    title: "Error",
    message: "Error adding new customer",
  });
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleEditInputChange = (val, editFieldName) => {
    setFormData({ ...formData, [editFieldName]: val });
  };

  const handleSetStatus = (val) => {
    setStatus(val === "Active" ? "Active" : "Inactive");
    setFormData({ ...formData, status: val });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await addCustomer(formData);
      setLoading(false);
      if (!response || response?.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Successfully added customer" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Add Customer
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form name="addCustomerForm" className="space-y-4 md:space-y-6">
              {AddCustomerField.map((field, index) => (
                <div key={index} className="flex flex-col items-start">
                  <label
                    htmlFor={field.label}
                    className="text-sm font-medium text-black"
                  >
                    {field.label}:
                  </label>
                  <Input
                    id={field.value}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    value={formData[field.value]}
                    onChange={(e) =>
                      handleEditInputChange(e.target.value, field.value)
                    }
                    placeholder={`Enter Your ${field.label}`}
                    type={
                      field.value === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : "text"
                    }
                    suffix={
                      field.value === "password" && (
                        <span onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <EyeTwoTone />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </span>
                      )
                    }
                  />
                </div>
              ))}
              <div className="items-start justify-start flex flex-col">
                <label>Status:</label>
                <Select
                  className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                  value={status}
                  placeholder="Select Status"
                  onChange={handleSetStatus}
                >
                  {/* You can customize the options here */}
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </div>

              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSubmit}
                loading={loading}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddCustomerForm;
