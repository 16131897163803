// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage,ref} from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLWfKKYwdsBc4ITc3xyTiekf-uXvS9OvU",
  authDomain: "paniwalastorage.firebaseapp.com",
  projectId: "paniwalastorage",
  storageBucket: "paniwalastorage.appspot.com",
  messagingSenderId: "1076455343677",
  appId: "1:1076455343677:web:318bb77220196b0d10cc54",
  measurementId: "G-DFMGH52TC2"
};

const app = initializeApp(firebaseConfig);
//This is pointing towards storage
export const storage = getStorage();

//This is the reference to / dir of storage
export const storageRef = ref(storage);

//And this is /images/ dir reference
// export const imageRef = ref(storage,'images/');

// Initialize Firebase

const analytics = getAnalytics(app);