import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { ApiProvider } from "./contexts/ApiProvider";
import AppLogout from "./components/AppLogout";
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <ApiProvider>
      <AppLogout>
      <App />
      </AppLogout>
      </ApiProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
