import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import { Input, Select } from "antd";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { ApiContext, useApiContext } from "../contexts/ApiProvider";
import { selected } from "@syncfusion/ej2/pivotview";
import { storageRef } from "../firebase";
import { uploadBytes } from "firebase/storage";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Modal } from "antd";


function AddProductForm({handleTabClick}) {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsOverflow, setTagsOverflow] = useState(false);
  const [brands, setBrands] = useState([]);
  const [url, setUrl] = useState("");
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error adding new Product "});
  const { getCategories, getSubCategories, getBrands, addProduct } =
    useApiContext();
  const [formData, setFormData] = useState({
    id: "",
    productName: "",
    productDesc: "",
    brandId: "",
    category: "",
    subcategory: "",
    mrp: "",
    discountPrice: "",
    discountPercent: "",
    hsn: "",
    gstPercent: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    status: "",
    brandDescription: "",
    adminId: "",
    barcode: "",
    packOf: "",
    logo: "",
    brandName: "",
  });
  const [suggestTags, setsuggestTags] = useState([]);
  const fileInputRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data in parallel
        const [categoriesData, subcategoriesData, brandsData] =
          await Promise.all([getCategories(), getSubCategories(), getBrands()]);

        // Set state after all data is fetched
        setCategories(categoriesData);
        setSubCategories(subcategoriesData);
        setsuggestTags(subcategoriesData.map((sub) => sub.subcategory));
        setBrands(brandsData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const pushTag = (tag) => {
    if (tags.length < 9) {
      setTags([...tags, tag]);
      if (tags.length === 8) {
        setTagsOverflow(true);
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      subcategory: tag, // Assuming the tag value directly corresponds to subcategory
    }));
  };
  const popTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    if (tagsOverflow && updatedTags.length <= 8) {
      setTagsOverflow(false);
    }
  };
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic, e.g., sending data to backend
    console.log(formData);

    // Reset form fields after submission
     try {
      setLoading(true)
      const response = await addProduct(formData);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"Successfully added product"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };

  // Function to handle file selection
  const handleFileChange = async (event) => {
    const name = event.target.name;

    const file = event.target.files[0];
    const imageRef = ref(storageRef, "images/" + file.name);
    //uploads the file
    uploadBytes(imageRef, file);

    console.log("handlefilechange");

    getDownloadURL(ref(imageRef))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
        console.log("url", url);
        console.log(name);
        setFormData((prevData) => ({
          ...prevData,
          [name]: [url],
        }));
        console.log("formdata: ", formData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // Function to handle form field changes
  const handleChange = async (event) => {
    const { name, value } = event.target;

    // If the name is brandName, update both brandName and brandId
    if (name === "brandName") {
      const selectedBrand = brands.find((brand) => brand.brand_name === value);
      const brandId = selectedBrand ? selectedBrand.brand_id : "";
      const logo = selectedBrand ? selectedBrand.logo : "";
      const brandDescription = selectedBrand ? selectedBrand.brand_desc : ";";
      setFormData((prevState) => ({
        ...prevState,
        brandName: value,
        brandId: brandId,
        logo: logo,
        brandDescription: brandDescription,
      }));
    } else if (name === "category") {
      // Update the form data
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      try {
        // Make an API request to fetch subcategories for the selected category
        const response = await fetch(
          `https://api.sfoly.com/getSubCategories?category=${encodeURIComponent(
            value
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch subcategories");
        }
        const data = await response.json();
        console.group(data)
        // Update the subCategories state with the received data
        setSubCategories(data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        // Handle error if needed
      }
    } else {
      // For other fields, update the form data as usual
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Add Product
            </h1>
          </div>
          <div className="p-6 space-y-4 border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="product-info flex flex-col flex-wrap justify-between">
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="productName"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Product Name:
                  </label>
                  <Input
                    type="text"
                    id="productName"
                    name="productName"
                    value={formData.productName}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Product`}
                  />
                </div>
                <div>
                  <div className="mb-4 w-6/12">
                    <label
                      htmlFor="category"
                      className="text-sm font-medium text-black flex flex-col items-start"
                    >
                      Category:
                    </label>
                    <select
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      className="h-[50px] w-[500px] mt-5 border-white text-black"
                    >
                      <option value="">Select a category... </option>
                      {categories.map((category, index) => (
                        <option key={index} value={category.category}>
                          {category.category}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-4 w-6/12">
                    <label
                      htmlFor="subcategory"
                      className="text-sm font-medium text-black flex flex-col items-start"
                    >
                      Subcategory:
                    </label>
                    <select
                      id="subcategory"
                      name="subcategory"
                      value={formData.subcategory}
                      onChange={handleChange}
                      className="h-[50px] w-[500px] mt-5 border-white text-black"
                    >
                      <option value="">Select a subcategory... </option>
                      {subCategories.map((subcategory, index) => (
                        <option key={index} value={subcategory.subcategory}>
                          {subcategory.subcategory}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="MRP"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    MRP:
                  </label>
                  <Input
                    type="text"
                    id="MRP"
                    name="mrp"
                    value={formData.mrp}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Mrp`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="DiscountedPrice"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Discounted Price:
                  </label>
                  <Input
                    type="text"
                    id="DiscountedPrice"
                    name="discountPrice"
                    value={formData.discountPrice}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Discounted Price`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="DiscountPercentage"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Discounted Percentage:
                  </label>
                  <Input
                    type="text"
                    id="DiscountPercentage"
                    name="discountPercent"
                    value={formData.discountPercent}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Discounted Percentage %`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="status"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Status:
                  </label>
                  <select
                    id="status"
                    className="h-[50px] w-[500px] mt-5 border-white text-black"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="">Select a status...</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="Brandid"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Brand ID:
                  </label>
                  <Input
                    type="text"
                    id="Brandid"
                    name="brandId"
                    value={formData.brandId}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    disabled
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="brands"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Brand Name:
                  </label>
                  <select
                    id="brands"
                    name="brandName" // Ensure the name matches the state key
                    value={formData.brandName}
                    onChange={handleChange}
                    className="h-[50px] w-[500px] mt-5 border-white text-black"
                  >
                    <option value="">Select a Brand...</option>
                    {brands.map((brand, index) => (
                      <option key={index} value={brand.brand_name}>
                        {brand.brand_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="hsn"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    HSN:
                  </label>
                  <Input
                    type="text"
                    id="hsn"
                    name="hsn"
                    value={formData.hsn}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your HSN`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="GSTPercent"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    GST Percent:
                  </label>
                  <Input
                    type="text"
                    id="GSTPercent"
                    name="gstPercent"
                    value={formData.gstPercent}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your GST Percentage %`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="AdminID"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Admin ID:
                  </label>
                  <Input
                    type="text"
                    id="AdminID"
                    name="adminId"
                    value={formData.adminId}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Admin ID`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="Barcode"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Barcode:
                  </label>
                  <Input
                    type="text"
                    id="Barcode"
                    name="barcode"
                    value={formData.barcode}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Barcode`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="Packof"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Pack Of:
                  </label>
                  <Input
                    type="text"
                    id="Packof"
                    name="packOf"
                    value={formData.packOf}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Pack Of`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="id"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    ID:
                  </label>
                  <Input
                    type="text"
                    id="id"
                    name="id"
                    value={formData.id}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Id:`}
                  />
                </div>
              </div>
              <div className="images w-6/12 flex flex-col">
                <label
                  htmlFor="image"
                  className="text-sm font-medium text-black flex flex-col
                items-start"
                >
                  Upload:
                </label>
                <div className="image flex flex-col items-start mt-5">
                  <div className="mb-4 flex">
                    <label
                      htmlFor="upload-file"
                      className={`${
                        loading
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-white hover:bg-gray-100 text-black"
                      } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                    >
                      Image 1
                    </label>
                    <input
                      id="upload-file"
                      name="image1"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden" // Hide the default input appearance
                    />
                  </div>
                  <div className="mb-4 flex">
                    <label
                      htmlFor="upload-file2"
                      className={`${
                        loading
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-white hover:bg-gray-100 text-black"
                      } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                    >
                      Image 2
                    </label>
                    <input
                      id="upload-file2"
                      name="image2"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden" // Hide the default input appearance
                    />
                  </div>
                  <div className="mb-4 flex">
                    <label
                      htmlFor="upload-file3"
                      className={`${
                        loading
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-white hover:bg-gray-100 text-black"
                      } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                    >
                      Image 3
                    </label>
                    <input
                      id="upload-file3"
                      name="image3"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden" // Hide the default input appearance
                    />
                  </div>
                  <div className="mb-4 flex">
                    <label
                      htmlFor="upload-file4"
                      className={`${
                        loading
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-white hover:bg-gray-100 text-black"
                      } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                    >
                      Image 4
                    </label>
                    <input
                      id="upload-file4"
                      name="image4"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden" // Hide the default input appearance
                    />
                  </div>
                  <div className="mb-4 flex">
                    <label
                      htmlFor="upload-file5"
                      className={`${
                        loading
                          ? "bg-green-500 hover:bg-green-700"
                          : "bg-white hover:bg-gray-100 text-black"
                      } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                    >
                      Image 5
                    </label>
                    <input
                      id="upload-file5"
                      name="image5"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden" // Hide the default input appearance
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 w-6/12">
                <label
                  htmlFor="logo"
                  className="text-sm font-medium mt-5 text-black flex flex-col items-start"
                >
                  Logo:
                </label>
                <img src={formData.logo} alt="logo" />
              </div>

              <div className="product-desc flex flex-col justify-between">
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="productDescription"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Product Description:
                  </label>
                  <textarea
                    id="productDescription"
                    name="productDesc"
                    value={formData.productDesc}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 pl-2 border-white text-black"
                    rows="5" // adjust the number of visible rows as needed
                    placeholder={`Enter Your Product Description`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="brandDescription"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Brand Description:
                  </label>
                  <textarea
                    id="brandDescription"
                    name="brandDescription"
                    value={formData.brandDescription}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    rows="5" // adjust the number of visible rows as needed
                    disabled
                  />
                </div>
              </div>
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 mt-5 ml-96 hover:border-transparent border-none rounded-none"
                onClick={handleSubmit}
                loading={loading} // Added the loading prop and passed the loading state variable
              >
                Submit
              </Button>

            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddProductForm;
