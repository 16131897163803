import React, { useEffect, useState } from "react";
import '../styling/image-upload.scss'
import Button from '@mui/material/Button';
import axios from 'axios';

const ImageUpload = (props) => {
    const [selectedfile, SetSelectedFile] = useState({
    })

    const [showDeleteBtn, handleShowDeleteBtn] = useState({
        image1: true,
        image2: true,
        image3: true
    })
    const [showUploadBtn, handleShowUploadBtn] = useState({
        image1: false,
        image2: false,
        image3: false
    })

    useEffect(() => {
        SetSelectedFile(props.logoAndImages)
    }, [props.logoAndImages])
    // {
    //     fileimage: "https://bisleri-shop-storage.s3.ap-south-1.amazonaws.com/products/May2022/o02MbFGeigvAvJ38657f.jpg",
    //     filename:"image1",
    //     filesize:"207.47 KB",
    //     filetype:"image/jpg",
    //     id:"1"
    // }
    // ]);
    const [Files, SetFiles] = useState([]);

    useEffect(() => {
        // console.log(selectedfile)
        // console.log(showDeleteBtn)
    })

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e,val) => {
        // console.log(e.target.files)
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return {
                        ...preValue,
                        [val]: reader.result,
                    }
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }


    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            SetSelectedFile((prevData) => {
                return { ...prevData, [id]: "" }
            });
            handleShowDeleteBtn((prevData) => {
                return { ...prevData, [id]: false }
            })
        } else {
            // alert('No');
        }

    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            for (let index = 0; index < selectedfile.length; index++) {
                SetFiles((preValue) => {
                    return [
                        ...preValue,
                        selectedfile[index]
                    ]
                })
            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }

    const handleFileUpload = (event) => {
        // get the selected file from the input
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("file", file);
        // make a POST request to the File Upload API with the FormData object and Rapid API headers
        axios
            .post("https://file-upload8.p.rapidapi.com/upload", formData, {
                headers: { 'content-type': 'multipart/form-data' }
            })
            .then((response) => {
                // handle the response
                // console.log(response);
            })
            .catch((error) => {
                // handle errors
                // console.log(error);
            });
    };

    const DeleteFile = async (id) => {

        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
        } else {
            // alert('No');
        }
    }

    const handleUpload = (e,id) => {
        // console.log("clicked")
        handleShowDeleteBtn((prevData)=>{
            return {
                ...prevData,[id]:true
            }
        })
    }

    return (

        <div className="fileupload-view">
            <div className="row justify-content-center m-0">
                <div className="col-md-6">
                    <div className="card mt-5">
                        <div className="card-body">
                            <div className="kb-data-box">



                                {/* Img1 */}
                                <form className="upload-form" onSubmit={FileUploadSubmit}>
                                    <div className="kb-modal-data-title">
                                        <div className="kb-data-title">
                                            <h6>Image 1:</h6>
                                        </div>
                                    </div>
                                    <div className="kb-attach-box mb-3">


                                        <div className="file-atc-box">
                                            <div className="file-image"> <img src={selectedfile.image1} alt="" /></div>

                                            {showDeleteBtn.image1 ?
                                                <div className="file-detail">
                                                    <div className="file-actions">
                                                        <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile("image1")}>Delete</button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="kb-file-upload">
                                                    <div className="file-upload-box">
                                                        <input type="file" id="fileupload" className="file-upload-input" onChange={(e)=>InputChange(e,"image1")} />
                                                        <span className="file-action-btn">Choose file</span>
                                                    </div>

                                                    <div className="file-upload-box">
                                                        <input type="button" id="fileupload" className="file-upload-input" onClick={(e)=>handleUpload(e,"image1")} />
                                                        <span className="file-action-btn">Upload</span>
                                                    </div>
                                                </div>
                                            }

                                            {/* {showUploadBtn.image1 &&
                                            <div className="kb-file-upload">
                                                <div className="file-upload-box">
                                                    <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} />
                                                    <span className="file-action-btn">Upload</span>
                                                </div>
                                            </div>
                                            } */}
                                        </div>

                                    </div>
                                    {/* <div className="kb-buttons-box"> */}
                                    {/* <Button className="save-btn" variant="contained" color="secondary" size="small" type="submit">Upload</Button> */}
                                    {/* <button type="submit" className="btn btn-primary form-submit">Upload</button> */}
                                    {/* </div> */}
                                </form>

                                {/* Img2 */}
                                <form className="upload-form" onSubmit={FileUploadSubmit}>
                                    <div className="kb-modal-data-title">
                                        <div className="kb-data-title">
                                            <h6>Image 2:</h6>
                                        </div>
                                    </div>
                                    <div className="kb-attach-box mb-3">


                                        <div className="file-atc-box">
                                            <div className="file-image"> <img src={selectedfile.image2} alt="" /></div>

                                            {showDeleteBtn.image2 ?

                                                <div className="file-detail">
                                                    <div className="file-actions">
                                                        <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile("image2")}>Delete</button>
                                                    </div>
                                                </div> :
                                                <div className="kb-file-upload">
                                                    <div className="file-upload-box">
                                                        <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} />
                                                        <span className="file-action-btn">Choose file</span>
                                                    </div>

                                                    <div className="file-upload-box">
                                                        <input type="button" id="fileupload" className="file-upload-input" onClick={(e)=>handleUpload(e,"image2")} />
                                                        <span className="file-action-btn">Upload</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    {/* <div className="kb-buttons-box"> */}
                                    {/* <Button className="save-btn" variant="contained" color="secondary" size="small" type="submit">Upload</Button> */}
                                    {/* <button type="submit" className="btn btn-primary form-submit">Upload</button> */}
                                    {/* </div> */}
                                </form>

                                {/* Img3 */}
                                <form className="upload-form" onSubmit={FileUploadSubmit}>
                                    <div className="kb-modal-data-title">
                                        <div className="kb-data-title">
                                            <h6>Image 3:</h6>
                                        </div>
                                    </div>
                                    <div className="kb-attach-box mb-3">


                                        <div className="file-atc-box">
                                            <div className="file-image"> <img src={selectedfile.image3} alt="" /></div>

                                            {showDeleteBtn.image3 ?

                                                <div className="file-detail">
                                                    <div className="file-actions">
                                                        <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile("image3")}>Delete</button>
                                                    </div>
                                                </div> :
                                                <div className="kb-file-upload">
                                                    <div className="file-upload-box">
                                                        <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} />
                                                        <span className="file-action-btn">Choose file</span>
                                                    </div>

                                                    <div className="file-upload-box">
                                                        <input type="button" id="fileupload" className="file-upload-input" onClick={(e)=>handleUpload(e,"image1")} />
                                                        <span className="file-action-btn">Upload</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    {/* <div className="kb-buttons-box"> */}
                                    {/* <Button className="save-btn" variant="contained" color="secondary" size="small" type="submit">Upload</Button> */}
                                    {/* <button type="submit" className="btn btn-primary form-submit">Upload</button> */}
                                    {/* </div> */}
                                </form>

                                {Files.length > 0 ?
                                    <div className="kb-attach-box">
                                        <hr />
                                        {
                                            Files.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={index}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div>
                                                            // <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p><span>Size : {filesize}</span></p>
                                                            <div className="file-actions">
                                                                <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ImageUpload;
