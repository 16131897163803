import React, { useState, useRef } from "react";
import Header from "./Header";
import { ApiContext, useApiContext } from "../contexts/ApiProvider";
import { Input, Select } from "antd";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { storageRef } from "../firebase";
import { uploadBytes } from "firebase/storage";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Modal } from "antd";

function AddDeliveryManForm({handleTabClick}) {
  const { addDM } = useApiContext();
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error adding new DM "});
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    aadhar_id: "",
    vehicle_no: "",
    mobile: "",
    alternate_mobile: "",
    email: "",
    creation_date: "",
    password: "",
    acc_number: "",
    ifsc: "",
    dm_type: "",
    daily_pay: "",
    per_meter_pay: "",
    status: "",
    is_approved: "",
    pan: "",
    driving_license_no: "",
    pan_img: "",
    driving_lic_img: "",
    aadhar_front: "",
    aadhar_back: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const fileInputRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
      setLoading(true)
      const response = await addDM(formData);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"Successfully added Delivery Man"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };
  const handleFileChange = async (event) => {
    const name = event.target.name;
    const file = event.target.files[0];
    const imageRef = ref(storageRef, "images/" + file.name);
    //uploads the file
    uploadBytes(imageRef, file);

    console.log("handlefilechange");

    getDownloadURL(ref(imageRef))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
        console.log("url", url);
        console.log(name);
        setFormData((prevData) => ({
          ...prevData,
          [name]: [url],
        }));
        console.log("formdata: ", formData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to trigger file Input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Add DeliveryMan
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="product-info flex flex-col flex-wrap justify-between">
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="id"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    ID:
                  </label>
                  <Input
                    type="text"
                    id="id"
                    name="id"
                    value={formData.id}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your ID`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="name"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Name:
                  </label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Name`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="aadhar_id"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Aadhar ID:
                  </label>
                  <Input
                    type="text"
                    id="aadhar_id"
                    name="aadhar_id"
                    value={formData.aadhar_id}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Adhar ID`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="vehicle_no"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Vehicle Number:
                  </label>
                  <Input
                    type="text"
                    id="vehicle_no"
                    name="vehicle_no"
                    value={formData.vehicle_no}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Vehicle Number`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="mobile"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Mobile:
                  </label>
                  <Input
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Mobile`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="alternate_mobile"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Alternate Mobile:
                  </label>
                  <Input
                    type="text"
                    id="alternate_mobile"
                    name="alternate_mobile"
                    value={formData.alternate_mobile}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Alternate Mobile`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="creation_date"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    creation Date:
                  </label>
                  <Input
                    type="text"
                    id="creation_date"
                    name="creation_date"
                    value={formData.creation_date}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Creation Date`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Email:
                  </label>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Email`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="password"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Password:
                  </label>
                  <Input
                    type="text"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Password`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="acc_number"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Account Number:
                  </label>
                  <Input
                    type="text"
                    id="acc_number"
                    name="acc_number"
                    value={formData.acc_number}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Account Number`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="ifsc"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    IFSC:
                  </label>
                  <Input
                    type="text"
                    id="ifsc"
                    name="ifsc"
                    value={formData.ifsc}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your IFSC`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="dm_type"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    DM Type:
                  </label>
                  <Input
                    type="text"
                    id="dm_type"
                    name="dm_type"
                    value={formData.dm_type}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Dm Type`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="daily_pay"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Daily Pay:
                  </label>
                  <Input
                    type="text"
                    id="daily_pay"
                    name="daily_pay"
                    value={formData.daily_pay}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Daily Pay`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="per_meter_pay"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Per Meter Pay:
                  </label>
                  <Input
                    type="text"
                    id="per_meter_pay"
                    name="per_meter_pay"
                    value={formData.per_meter_pay}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Per Meter Pay`}
                  />
                </div>

                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="status"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Status:
                  </label>
                  <select
                    id="status"
                    className="h-[50px] w-[500px] mt-5 border-white text-black"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="">Select a status...</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="is_approved"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Is Approved
                  </label>
                  <select
                    id="is_approved"
                    className="h-[50px] w-[500px] mt-5 border-white text-black"
                    name="is_approved"
                    value={formData.is_approved}
                    onChange={handleChange}
                  >
                    <option value="">is it approved...</option>
                    <option value="yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="pan"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    PAN:
                  </label>
                  <Input
                    type="text"
                    id="pan"
                    name="pan"
                    value={formData.pan}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your PAN`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="driving_license_no"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Driving License No:
                  </label>
                  <Input
                    type="text"
                    id="driving_license_no"
                    name="driving_license_no"
                    value={formData.driving_license_no}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Driving License No`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="address"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Address:
                  </label>
                  <Input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Address`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="city"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    City:
                  </label>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your City`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="state"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    State:
                  </label>
                  <Input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your State`}
                  />
                </div>
                <div className="mb-4 w-6/12">
                  <label
                    htmlFor="pincode"
                    className="text-sm font-medium text-black flex flex-col items-start"
                  >
                    Pincode:
                  </label>
                  <Input
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className="h-[50px] w-[1000px] mt-5 border-white text-black"
                    placeholder={`Enter Your Pincode`}
                  />
                </div>
                <div className="images w-6/12 flex flex-col">
                  <label
                    htmlFor="image"
                    className="text-sm font-medium text-black flex flex-col
                items-start"
                  >
                    Upload:
                  </label>
                  <div className="image flex flex-col items-start mt-5">
                    <div className="mb-4 flex">
                      <label
                        htmlFor="upload-file"
                        className={`${
                          loading
                            ? "bg-green-500 hover:bg-green-700"
                            : "bg-white hover:bg-gray-100 text-black"
                        } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                      >
                        Pan Image
                      </label>
                      <input
                        id="upload-file"
                        name="pan_img"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </div>
                    <div className="mb-4 flex">
                      <label
                        htmlFor="upload-file2"
                        className={`${
                          loading
                            ? "bg-green-500 hover:bg-green-700"
                            : "bg-white hover:bg-gray-100 text-black"
                        } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                      >
                        Driving License
                      </label>
                      <input
                        id="upload-file2"
                        name="driving_lic_img"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </div>

                    <div className="mb-4 flex">
                      <label
                        htmlFor="upload-file4"
                        className={`${
                          loading
                            ? "bg-green-500 hover:bg-green-700"
                            : "bg-white hover:bg-gray-100 text-black"
                        } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                      >
                        Aadhar front
                      </label>
                      <input
                        id="upload-file4"
                        name="aadhar_front"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden" // Hide the default input appearance
                      />
                    </div>
                    <div className="mb-4 flex">
                      <label
                        htmlFor="upload-file5"
                        className={`${
                          loading
                            ? "bg-green-500 hover:bg-green-700"
                            : "bg-white hover:bg-gray-100 text-black"
                        } text-white font-bold py-2 px-4 rounded cursor-pointer`}
                      >
                        Aadhar Back
                      </label>
                      <input
                        id="upload-file5"
                        name="aadhar_back"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden" // Hide the default input appearance
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 mt-5 ml-96 hover:border-transparent border-none rounded-none"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddDeliveryManForm;
