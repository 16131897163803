import React, { useState, useEffect } from "react";
import { Table, Button, Popover, Select, Input, Modal } from "antd";
import { BiEdit } from "react-icons/bi";
import { Header } from "./index.js";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import { useNavigate } from "react-router-dom";
import "../styling/order.css";
import { ordersData } from "../data/dummy.js";

const { Option } = Select;
const { Search } = Input;

export default function OrderTable() {
  const navigate = useNavigate();
  const { saveIndex } = useStateContext();
  const [OrderData, setOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [dmList, setDmList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [assignDmVisible, setAssignDmVisible] = useState(false);
  const { getAllOrders, getAllDm, assignDm , getProductDetails } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await getAllOrders({
          limit: pageSize,
          offset: (page - 1) * pageSize,
        });
        setLoading(false);
        if(response)
        setOrderData(response);
        else setAlertShown(true)
        
      } catch (error) {
        setLoading(false);
        setAlertShown(true)
        console.log("Error fetching orders:", error);
      }
    };

    fetchData();
  }, [page, pageSize]);


  

useEffect(() => {
  const fetchData = async () => {
    try {
      const updatedDetails = {}; // New object to hold updated details
      setDetailLoading(true);
      for (const order of OrderData) {
        if(order.order_id){
        const details = await getProductDetails(order.order_id);
        console.log(order.order_id,":",details);
        updatedDetails[order.order_id] = details }
        else updatedDetails[order.order_id] = []
      }
      setProductDetails(updatedDetails); // Update the state with all details
      setDetailLoading(false);
    } catch (error) {
      setDetailLoading(false);
      setAlertShown(true);
      console.log("Error fetching orders:", error);
    }
  };
  

  fetchData();
}, [OrderData]);

  

  useEffect(() => {
    const fetchDmList = async () => {
      try {
        const dmList = await getAllDm();
        setDmList(dmList);
      } catch (error) {
        console.log("Error fetching DM list:", error);
      }
    };

    fetchDmList();
  }, []);

  const handleExpand = (expanded, record) => {
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const handleEditRowBtn = (id) => {
    saveIndex(id);
    navigate("/updateOrder");
  };

  const handleAssignDm = (OrderId) => {
    setSelectedOrder(OrderId);
    setAssignDmVisible(true);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setPage(1); // Reset page number when changing page size
  };


  const handleDmSelection = async (dm) => {
    await assignDm(dm,selectedOrder,sessionStorage.getItem('adminId'))
    window.location.reload();
    setAssignDmVisible(false);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const product=[{ name: "vishal",
    mrp: 14,
    cost: 16,
    quantity: 4}]
  const filteredData = OrderData.filter((item) => {
    if (!item) return false; // Skip null or undefined items
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      if (
        (item.order_id && item.order_id.toString().includes(searchTermLower)) ||
        (Array.isArray(item.customer_id) &&
          item.customer_id.some((id) => id && id.includes(searchTermLower))) ||
        (item.address_id &&
          item.address_id.toString().includes(searchTermLower)) ||
        (item.products && item.products.toString().includes(searchTermLower)) ||
        (item.delivery_man_name && item.delivery_man_name.toString().toLowerCase().includes(searchTermLower))
      ) {
        return true;
      }
      return false;
    }
    return true; // If no search term, return true for all items
  });
  const productColumns = [
    {title:"Name",dataIndex:"name"},
    {title:"MRP",dataIndex:"mrp"},
    {title:"Cost",dataIndex:"cost"},
    {title:"Quantity",dataIndex:"quantity"}
  ]

  let columns = [
    { title: "ID", dataIndex: "order_id" },
    { title: "Customer ID", dataIndex: "customer_id" }, // Exclude this from expanded row
   // Inside the columns definition
{
  title: "Products",
  dataIndex: "products",
  render: (products,record) => (
    <Popover
      content={
        <Table
          dataSource={productDetails[record.order_id]}
          columns={productColumns}
          pagination={false}
          loading={detailLoading}
        />
      }
      title="Products"
      trigger="click"
    >
      <Button type="link">View</Button>
    </Popover>
  ),
},

    
    { title: "Delivery Man", dataIndex: "delivery_man_name" },
    { title: "DM Assigned By", dataIndex: "dm_assigned_by" },
    { title: "DM Assigning Time", dataIndex: "dm_assigning_time" },
    { title: "Store ID", dataIndex: "store_id" },
    { title: "Refund ID", dataIndex: "refund_id" },
    { title: "Status", dataIndex: "status" },
    {
      title: "Assign DM",
      render: (_, record) => (
        <Button onClick={() => handleAssignDm(record.order_id)}>
          Assign DM
        </Button>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.order_id)}>Edit</Button>
      ),
    },
  ];

  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Assign Dm')) {
    columns = columns.filter(column => column.title !== "Assign DM");
  }
  if (!permissions.includes('Update Orders')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  const firstSetColumns = [
    {
      dataIndex: "field",
      width: "50%",
    },
    {
      dataIndex: "value",
      width: "50%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
  ];

  const secondSetColumns = [
    {
      dataIndex: "field",
      width: "50%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
    {
      dataIndex: "value",
      width: "50%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
  ];

  const thirdSetColumns = [
    {
      dataIndex: "field",
      width: "12%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
    {
      dataIndex: "value",
      width: "12%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
  ];

  const fourthSetColumns = [
    {
      dataIndex: "field",
      width: "12%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
    {
      dataIndex: "value",
      width: "12%",
      style: { backgroundColor: "#f0f0f0", border: 0 },
    },
  ];
  const expandedRowRender = (record) => {
    const { key, customer_id, ...rest } = record; // Exclude customer_id from rest
    const entries = Object.entries(rest);
    const quarterLength = Math.ceil(entries.length / 4);

    const firstColumnData = entries.slice(0, quarterLength);
    const secondColumnData = entries.slice(quarterLength, quarterLength * 2);
    const thirdColumnData = entries.slice(quarterLength * 2, quarterLength * 3);
    const fourthColumnData = entries.slice(quarterLength * 3);

    const renderColumnData = (columnData) => {
      const keyColumnWidth = 150;
      const valueColumnWidth = 200;

      return (
        <ul style={{ paddingLeft: 0, listStyle: "none", margin: 0 }}>
          {columnData.map(([key, value]) => (
            <li key={key} style={{ marginBottom: "8px", display: "flex" }}>
              <strong
                style={{
                  width: `${keyColumnWidth}px`,
                  flexShrink: 0,
                }}
              >
                {key}:
              </strong>{" "}
              <span
                style={{
                  maxWidth: `${valueColumnWidth}px`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {key === "products" ? (
                  <Popover
                    content={<ProductList products={value} />}
                    title="Products"
                    trigger="click"
                  >
                    <Button type="link" className="expanded-view">
                      View
                    </Button>
                  </Popover>
                ) : (
                  value
                )}
              </span>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: "#fafafa",
        }}
      >
        <div style={{ width: "25%" }}>{renderColumnData(firstColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(secondColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(thirdColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(fourthColumnData)}</div>
      </div>
    );
  };

  const ProductList = ({ products }) => {
    return (
      // <ul
      // style={{ maxHeight: "300px", overflowY: "auto", padding: 0, margin: 0 }}
      // >
      <div>{products}</div>
      // </ul>
    );
  };
  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        {/* <Header title="Admin Table" /> */}
        <h1 class="text-3xl text-[#03c9d7] mb-4">Order Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <label className="text-[#03c9d7] text-xl">Select Status:</label>
          <Select
            placeholder="Filter by Status"
            style={{ width: 200, marginLeft: 5 }}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <Option value="">All</Option>
            <Option value="created">Created</Option>
            <Option value="completed">Completed</Option>
            <Option value="refund">Refund</Option>
            <Option value="partial-refund">Partial Refund</Option>
            <Option value="cancelled">Cancelled</Option>
          </Select>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 800 }}
          />
        </div>

        <Table
          dataSource={filteredData.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: handleExpand,
          }}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            total: OrderData.length,
          }}
          loading={loading}
        />

        {/* Pop-up for assigning DM */}
        <Modal
          title="Assign DM"
          open={assignDmVisible}
          onCancel={() => setAssignDmVisible(false)}
          footer={null}
        >
          <Select
            placeholder="Select DM"
            style={{ width: "100%" }}
            onChange={handleDmSelection}
          >
            {dmList.map((dm) => (
              <Option key={dm.id} value={dm.id}>
                {dm.name}
              </Option>
            ))}
          </Select>
        </Modal>
      </div>
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Orders");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching order data</p>
        </Modal>
    </div>
  );
}
