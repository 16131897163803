import React, { useState, useEffect } from "react";
import { Table, Button, Select, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import Popup from "../components/EditProductsPopup";
import { brandColumn, productsColumn } from "../data/dummy";
import { Header } from "../components";
import { productsPopupEditField } from "../data/dummy.js";
import { statuses } from "../data/dummy";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import { Modal } from "antd";
const { Option } = Select;
const { Search } = Input;
export default function BrandTable() {
  const { saveIndex } = useStateContext();
  const navigate = useNavigate();
  const [brandData, setBrandData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [productEditData, setProductEditData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [statusFilter, setStatusFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [brand_nameSortOrder, setbrand_nameSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { getBrands } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    console.log("mounted parent");
    getBrandApi();
    console.log("Products data updated:", brandData);
  }, []);

  const getBrandApi = async () => {
    try {
      setLoading(true);
      const response = await getBrands();
      setLoading(false);
      if(response){
      console.log("response: ", response);
      setBrandData(response);}
      else setAlertShown(true)
    } catch (err) {
      setLoading(false);
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };

  const handleEditRowBtn = (index) => {
    console.log(index);
    saveIndex(index);
    navigate("/updateBrand");
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handlebrand_nameSort = () => {
    setbrand_nameSortOrder((prev) =>
      prev === "ascend" ? "descend" : "ascend"
    );
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = brandData.filter((item) => {
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchTerm) {
      return (
        item.brand_id.toString().includes(searchTerm) ||
        item.brand_name.toLowerCase().includes(searchTerm)
      );
    }
    return true;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const brand_nameA = a.brand_name.toUpperCase();
    const brand_nameB = b.brand_name.toUpperCase();
    return brand_nameSortOrder === "ascend"
      ? brand_nameA.localeCompare(brand_nameB)
      : brand_nameB.localeCompare(brand_nameA);
  });

  let columns = [
    {
      title: "Brand ID",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Description",
      dataIndex: "brand_desc",
      key: "brand_desc",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (text, record) => (
        <img className="rounded-xl h-20 md:ml-3" src={text} alt="order-item" />
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.brand_id)}>Edit</Button>
      ),
    },
  ];

    //Check access for edit button
    const permissions= sessionStorage.getItem('permissions');
    if (!permissions.includes('Update Brands')) {
      columns = columns.filter(column => column.title !== "Actions");
    }

  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        {/* <Header title="Admin Table" /> */}
        <h1 class="text-3xl text-[#03c9d7] mb-4">Brand Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          {/* <label className="text-[#03c9d7] text-xl">Select Status:</label> */}
          <Button onClick={handlebrand_nameSort} className="ml-24">
            Sort Brand Name
          </Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 1000 }}
          />
        </div>
        <Table
          dataSource={
            searchTerm
              ? brandData.filter((item) => {
                  const searchLower = searchTerm.toLowerCase();
                  return (
                    item.brand_id.toString().includes(searchLower) ||
                    item.brand_name.toLowerCase().includes(searchLower)
                  );
                })
              : sortedData.map((item, index) => ({
                  ...item,
                  key: index,
                }))
          }
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: brandData.length,
            onChange: (page, pageSize) => setPage(page),
            onShowSizeChange: (current, size) => setPageSize(size),
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          loading={loading}
        />
      </div>
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Brands");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching brand data</p>
        </Modal>
    </div>
  );
}
