import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import Box from "@mui/material/Box";
import { Input, Select, Button, message } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { OrderEditField } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { GiConsoleController } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const UpdateOrderForm = () => {
  const navigate = useNavigate();
  const { index } = useStateContext();
  const { getAllOrders, updateOrder } = useApiContext();
  const [OrderData, setOrderData] = useState([]);
  const [inputFieldVal, setInputFieldVal] = useState({});
  const [status, setStatus] = useState("Active");
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error updating Order data"});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllOrders();
        console.log("Fetched Order data:", response);
        if(response)
        setOrderData(response);
        else setAlertShown(true)
      } catch (error) {
        setAlertShown(true)
        console.error("Error fetching Order data:", error);
      }
    };

    fetchData();
  }, [getAllOrders]);

  useEffect(() => {
    const Order = OrderData.find((Order) => Order.order_id === index);
    // console.log(Order, Order.id, index);
    console.log(index);
    if (Order) {
      setInputFieldVal(Order);
      setStatus(Order.status);
    }
  }, [OrderData, index]);

  const handleEditInputChange = (val, editFieldName) => {
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val });
  };

  const handleCancel = () => {
    const originalOrder = OrderData.find((Order) => Order.order_id === index);
    setInputFieldVal(originalOrder);
    setStatus(originalOrder.status);
    navigate('/orders')
  };

  const handleSave = async () => {
    console.log("save");
    try {
      setLoading(true)
      const response = await updateOrder(inputFieldVal);
      console.log(response);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"successfully updated order data"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update Order
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {OrderEditField.map((field, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={field.label}
                  className="text-sm font-medium text-black"
                >
                  {field.label}:
                </label>
                {/* <div className="mb-4" key={index}> */}
                <Input
                  value={inputFieldVal[field.value] || ""}
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  onChange={(e) =>
                    handleEditInputChange(e.target.value, field.value)
                  }
                  placeholder={`Enter Your ${field.label}`}
                />
              </div>
            ))}

            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Orders");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateOrderForm;
