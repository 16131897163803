import React, { useState, useEffect } from "react";
import { Table, Button, Select, Input } from "antd";
import Popup from "../components/EditProductsPopup";
import { productsColumn } from "../data/dummy";
import { Header } from "../components";
import { productsPopupEditField } from "../data/dummy.js";
import { statuses } from "../data/dummy";
import { Link, useNavigate } from "react-router-dom";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import { Modal } from "antd";

const { Option } = Select;
const { Search } = Input;

export default function ProductTable() {
  const { saveIndex } = useStateContext();
  const navigate = useNavigate();

  const [productsData, setProductsData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [product_nameSortOrder, setProduct_nameSortOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [alertShown, setAlertShown] = useState(false);

  const { getProducts } = useApiContext();

  useEffect(() => {
    getProductsApi();
  }, []);

  const getProductsApi = async () => {
    try {
      setLoading(true);
      const response = await getProducts();
      setLoading(false);
      if(response)
      setProductsData(response);
      else setAlertShown(true)
      
    } catch (err) {
      setLoading(false);
      setAlertShown(true)
      console.log("Error fetching products: ", err);
    }
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handleProductNameSort = () => {
    setProduct_nameSortOrder((prev) =>
      prev === "ascend" ? "descend" : "ascend"
    );
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setPage(1); // Reset page number when changing page size
  };
  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = productsData.filter((item) => {
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchTerm) {
      return (
        item.id.toString().includes(searchTerm) ||
        item.product_name.toLowerCase().includes(searchTerm) ||
        item.category.toLowerCase().includes(searchTerm) ||
        item.subcategory.toLowerCase().includes(searchTerm)
      );
    }
    return true;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const product_nameA = a.product_name.toUpperCase();
    const product_nameB = b.product_name.toUpperCase();
    return product_nameSortOrder === "ascend"
      ? product_nameA.localeCompare(product_nameB)
      : product_nameB.localeCompare(product_nameA);
  });

  const handleEditRowBtn = (index) => {
    saveIndex(index);
    navigate("/updateProduct");
  };

  let columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Product Name", dataIndex: "product_name", key: "product_name" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Subcategory", dataIndex: "subcategory", key: "subcategory" },
    { title: "MRP", dataIndex: "mrp", key: "mrp" },
    { title: "Discounted Price", dataIndex: "disc_price", key: "disc_price" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Brand Name", dataIndex: "brand_name", key: "brand_name" },
    {
      title: "Image1",
      dataIndex: "image1",
      key: "image1",
      render: (text) => <img src={text} alt="product" style={{ width: 50 }} />,
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.id)}>Edit</Button>
      ),
    },
  ];

  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (permissions&&!permissions.includes('Update Product')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 class="text-3xl text-[#03c9d7] mb-4">Product Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <label className="text-[#03c9d7] text-xl">Select Status:</label>
          <Select
            style={{ width: 120, marginRight: "16px" }}
            placeholder="Filter Status"
            onChange={handleStatusFilter}
            value={statusFilter}
          >
            <Option value="">All</Option>
            <Option value="Active">Active</Option>
            <Option value="Pending">Pending</Option>
          </Select>
          <Button onClick={handleProductNameSort}>Sort Name</Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 800 }}
          />
        </div>
        <Table
          dataSource={sortedData.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            total: filteredData.length,
          }}
          loading={loading}
        />
      </div>
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Products");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching product data</p>
        </Modal>
    </div>
  );
}
