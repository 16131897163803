import React, { useState, useEffect } from "react";
import { Table, Button, Select, Input, message } from "antd";
import { BiEdit } from "react-icons/bi";
import { Header } from "../components";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "antd";
const { Option } = Select;
const { Search } = Input;

export default function AdminTable({ onAddAdmin }) {
  const navigate = useNavigate();
  const { saveIndex } = useStateContext();
  const [adminData, setAdminData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameSortOrder, setNameSortOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [alertShown, setAlertShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { getAdmin, updateAdmin } = useApiContext();
  

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        setLoading(true);
        const response = await getAdmin();
        console.log(response.name)
        setLoading(false);
        if(response)
        setAdminData(response);
        else
          setAlertShown(true);
        
      } catch (error) {
        setLoading(false);
        setAlertShown(true);
        console.log("Error fetching admin data:", error);
      }
    };

    fetchAdmins();
  }, []);

  const handleEditRowBtn = async (id) => {
    try {
      saveIndex(id);
        navigate("/updateAdmin");
    } catch (error) {
      console.error("Error editing row:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setPage(1); // Reset page number when changing page size
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handleNameSort = () => {
    setNameSortOrder((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = adminData.filter((item) => {
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchTerm) {
      return (
        item.id.toString().includes(searchTerm) ||
        item.name.toLowerCase().includes(searchTerm) ||
        item.email.toLowerCase().includes(searchTerm)
      );
    }
    return true;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameSortOrder === "ascend"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  let columns = [
    { title: "ID", dataIndex: "id" },
    { title: "Name", dataIndex: "name" },
    { title: "Email", dataIndex: "email" },
    { title: "Status", dataIndex: "status" },
    { title: "Role", dataIndex: "role" },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.id)}>Edit</Button>
      ),
    },
  ];
  
  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Update Admin')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 class="text-3xl text-[#03c9d7] mb-4">Admin Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <label className="text-[#03c9d7] text-xl">Select Status:</label>
          <Select
            style={{ width: 120, marginRight: "16px", marginLeft: "10px" }}
            placeholder="Filter Status"
            onChange={handleStatusFilter}
            value={statusFilter}
          >
            <Option value="">All</Option>
            <Option value="Active">Active</Option>
            <Option value="Pending">Pending</Option>
          </Select>
          <Button onClick={handleNameSort}>Sort Name</Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 800 }}
          />
        </div>
        <Table
          dataSource={sortedData.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            total: filteredData.length,
          }}
          loading={loading}
        />
      </div>
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Admin");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching admin data</p>
        </Modal>
    </div>
  );
}
