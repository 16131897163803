import React, { useState, useEffect } from "react";
import "../styling/popup.css";
import { Input, Select, Button, message } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { DmEditField } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from ".";
import { GiConsoleController } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const UpdateDeliveryManForm = () => {
  const navigate= useNavigate();
  const { index } = useStateContext();
  const { getAllDm, updateDm } = useApiContext();
  const [DmData, setDmData] = useState([]);
  const [inputFieldVal, setInputFieldVal] = useState({});
  const [status, setStatus] = useState("Active");
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error updating delivery man data"});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDm();
        if(response){
        setDmData(response);}
        else setAlertShown(true)
      } catch (error) {
        setAlertShown(true)
        console.error("Error fetching Dm data:", error);
      }
    };

    fetchData();
  }, [getAllDm]);

  useEffect(() => {
    const Dm = DmData.find((Dm) => Dm.id === index);
    // console.log(Dm, Dm.id, index);
    console.log(Dm);
    if (Dm) {
      setInputFieldVal(Dm);
      setStatus(Dm.status);
    }
  }, [DmData, index]);

  const handleEditInputChange = (val, editFieldName) => {
    setInputFieldVal({ ...inputFieldVal, [editFieldName]: val });
  };

  const handleCancel = () => {
    const originalDm = DmData.find((Dm) => Dm.id === index);
    setInputFieldVal(originalDm);
    setStatus(originalDm.status);
    navigate('/DeliveryMan');
  };

  const handleSave = async () => {
    console.log("save");
    try {
      setLoading(true)
      const response = await updateDm(inputFieldVal);
      console.log(response);
      setLoading(false)
      if (response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"successfully updated delivery man data!!"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true)
      setLoading(false)
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Update DeliveryMan
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            {DmEditField.map((DmEditField, index) => (
              <div key={index} className="flex flex-col items-start">
                <label
                  htmlFor={DmEditField.label}
                  className="text-sm font-medium text-black"
                >
                  {DmEditField.label}:
                </label>
                <Input
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  id="outlined-required"
                  value={inputFieldVal[DmEditField.value]}
                  onChange={(e) =>
                    handleEditInputChange(e.target.value, DmEditField.value)
                  }
                  variant="standard"
                  label={DmEditField.label}
                  placeholder={`Enter Your ${DmEditField.label}`}
                />
              </div>
            ))}

            <div className="flex justify-center space-x-4">
              <Button
                type="primary"
                className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSave}
                loading={loading}
              >
                SAVE
              </Button>
              <Button
                type="primary"
                className="items-center justify-center bg-red-500 w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/DeliveryMan");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
};

export default UpdateDeliveryManForm;
