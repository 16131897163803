import React, { useEffect, useState } from "react";
import axios from "axios";
import { Header } from "../components";
import "../styling/stores.css";
import { Table, Button, Select, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import AddStorePopup from "../components/AddStorePopup";
import { storesColumn } from "../data/dummy";
import { statuses, storeEditField } from "../data/dummy";
import { useApiContext } from "../contexts/ApiProvider";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { Modal } from "antd";
const { Option } = Select;
const { Search } = Input;
const StoreTable = () => {
  const { saveIndex } = useStateContext();
  const { getStore, getStoreId } = useApiContext();
  const [storeData, setstoreData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [nameSortOrder, setnameSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [alertShown, setAlertShown] = useState(false);
  useEffect(() => {
    getStoresApi();
  }, []);

  const getStoresApi = async () => {
    try {
      setLoading(true);
      const response = await getStore();
      setLoading(false);
      if (response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setstoreData(response);
      setTotalItems(response.length);
      
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occurred ", err);
      setLoading(false);
    }
  };

  const handleEditRowBtn = (index) => {
    saveIndex(index);
    navigate("/updateStore");
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handlefirstNameSort = () => {
    setnameSortOrder((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };
  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = storeData.filter((item) => {
    if (!item) return false; // Skip null or undefined items
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase().trim();
      return (
        (item.store_id && item.store_id.toString().includes(searchTermLower)) ||
        (item.name && item.name.toLowerCase().includes(searchTermLower)) ||
        (item.street_address &&
          item.street_address.toLowerCase().trim().includes(searchTermLower)) ||
        (item.phone && item.phone.toString().includes(searchTermLower))
      );
    }
    return true; // If no search term, return true for all items
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const nameA = (a.name || "").toUpperCase();
    const nameB = (b.name || "").toUpperCase();
    return nameSortOrder === "ascend"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });
  let columns = [
    {
      title: "Store ID",
      dataIndex: "store_id",
      key: "store_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => (
        <span>{`${record.street_address}, ${record.city}, ${record.state}-${record.pincode}`}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Button
          icon={<BiEdit />}
          onClick={() => handleEditRowBtn(record.store_id)}
        >
          Edit
        </Button>
      ),
    },
  ];

    //Check access for edit button
    const permissions= sessionStorage.getItem('permissions');
    if (!permissions.includes('Update Stores')) {
      columns = columns.filter(column => column.title !== "Actions");
    }

  return (
    <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
      {/* <Header title="Admin Table" /> */}
      <h1 class="text-3xl text-[#03c9d7] mb-4">Store Table</h1>

      <div style={{ marginBottom: "16px", marginTop: "25px" }}>
        <label className="text-[#03c9d7] text-xl">Select Status:</label>{" "}
        <Select
          style={{ width: 120, marginRight: "16px" }}
          placeholder="Filter Status"
          onChange={handleStatusFilter}
        >
          <Option value="">All</Option>
          <Option value="Active">Active</Option>
          <Option value="Inactive">Inactive </Option>
        </Select>
        <Button onClick={handlefirstNameSort}>Sort Name</Button>
        <Search
          placeholder="Search here"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200, marginLeft: 800 }}
        />
      </div>
      <Table
        dataSource={sortedData}
        columns={columns}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => setPage(page),
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50"],
          total: storeData.length,
        }}
        loading={loading}
      />
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/Stores");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching store data</p>
        </Modal>
    </div>
  );
};
export default StoreTable;
