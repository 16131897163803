import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styling/signin.css";
import md5 from "md5";
import { useStateContext } from "../contexts/ContextProvider";
import { useApiContext } from "../contexts/ApiProvider";
import Cookies from "js-cookie";
import { updatesidebar } from "./Sidebar";

const SignIn = () => {
  const navigate = useNavigate();
  /* IMP: You need to include api here to use them */
  const { adminLogin } = useApiContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { signInSuccessful, handleSetAdminName, userLoggedIn } =
    useStateContext();

  const signIn = async () => {
    try {
      setIsLoading(true);
      const pwd = md5(password);
      const response = await adminLogin(email, pwd);
      if (response?.errors) {
        console.log("Something went wrong during signing in: ", response);
        setError(response?.errors[0].msg);
        return;
      }
      updatesidebar(response.permissions);
      signInSuccessful();
      handleSetAdminName(response?.name);
      const token = response.token;
      sessionStorage.setItem('permissions', response?.permissions);
      sessionStorage.setItem('adminId', response?.adminId);
      sessionStorage.setItem('email', response?.email);
      sessionStorage.setItem('role', response?.role);
      // localStorage.setItem("jwt", token);
      // localStorage.setItem("adminId", email);
      Cookies.set("jwt", token, { expires: 7 });
      Cookies.set("adminId", email, { expires: 7 });
      // Cookies.set("adminId", adminId, { expires: 7 });
      navigate("/ecommerce");
    } catch (err) {
      setError(err);
      console.log("Some error occurred during signing in: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isNotValidPassword(pwd) {
    return pwd == null || pwd === "" || pwd.length < 6 || pwd.length > 12;
  }

  const handleEmailChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    if (isNotValidPassword(event.target.value)) {
      setError("Password is invalid");
    } else {
      setError(null);
    }

    setPassword(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      signIn();
    }
  };

  return (
    !userLoggedIn && (
      <div className="w-full h-screen flex justify-center items-center bg-gray-800">
        <div className="w-1/2 h-1/2 shadow-lg rounded-md bg-white p-8 flex flex-col">
          <h2 className="text-center font-medium text-2xl mb-4">
            Admin Sign in
          </h2>
          <div className="flex flex-1 flex-col justify-evenly">
            <input
              className="border-2 outline-none p-2 rounded-md"
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <input
              className="border-2 outline-none p-2 rounded-md"
              type="password"
              placeholder="type your password..."
              value={password}
              required
              onKeyDown={handleKeyDown}
              onChange={handlePasswordChange}
              onkey
            />
            {error && <h2 style={{ color: "red" }}>{error}</h2>}

            <button className="signin-btn" onClick={signIn} disabled={error}>
              {!isLoading ? (
                "SIGN IN"
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="loading"></span>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SignIn;
