import React, { useState, useEffect } from "react";
import { Input, Select, Button, Modal } from "antd";
import { useApiContext } from "../contexts/ApiProvider";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

function AddAdminForm({ handleTabClick }) {
  const navigate = useNavigate();
  const { addAdmin, getRoles } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    title: "Error",
    message: "Error adding new admin",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "", // Added role field
  });
  const [roles, setRoles] = useState([]); // State to store roles data

  useEffect(() => {
    // Fetch roles data when the component mounts
    async function fetchRoles() {
      try {
        const response = await getRoles();
        console.log(response)
        if (response) {
          setRoles(response); // Assuming the roles data is in response.data.roles
        }
      } catch (error) {
        console.log("Error fetching roles:", error);
      }
    }

    fetchRoles(); // Call the function to fetch roles
  }, [getRoles]);


  const handleEditInputChange = (val, editFieldName) => {
    setFormData({ ...formData, [editFieldName]: val });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await addAdmin(formData);
      setLoading(false);
      if (!response || response.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Successfully added admin" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Add Admin
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form name="addAdminForm" className="space-y-4 md:space-y-6">
              <div className="flex flex-col items-start">
                <label htmlFor="name" className="text-sm font-medium text-black">
                  Name:
                </label>
                <Input
                  id="name"
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  value={formData.name}
                  onChange={(e) => handleEditInputChange(e.target.value, "name")}
                  placeholder="Enter Name"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="email" className="text-sm font-medium text-black">
                  Email:
                </label>
                <Input
                  id="email"
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  value={formData.email}
                  onChange={(e) => handleEditInputChange(e.target.value, "email")}
                  placeholder="Enter Email"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="password" className="text-sm font-medium text-black">
                  Password:
                </label>
                <Input.Password
                  id="password"
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  value={formData.password}
                  onChange={(e) => handleEditInputChange(e.target.value, "password")}
                  placeholder="Enter Password"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="role" className="text-sm font-medium text-black">
                  Role:
                </label>
                <Select
                  id="role"
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  value={formData.role}
                  onChange={(value) => handleEditInputChange(value, "role")}
                  placeholder="Select Role"
                >
                  {roles.map((role, index) => (
                    <Option key={index} value={role.role}>
                      {role.role}
                    </Option>
                  ))}
                </Select>

              </div>
              <Button
                type="primary"
                className="bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                onClick={handleSubmit}
                loading={loading}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddAdminForm;
