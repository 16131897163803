import React, { useState, useEffect } from "react";
import { Table, Button, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const { Search } = Input;

export default function CustomerTable() {
  const navigate = useNavigate();
  const { saveIndex } = useStateContext();
  const { getCustomer } = useApiContext();
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [first_nameSortOrder, setfirst_nameSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({title:"Error", message:"Error fetching Customer data"});

  useEffect(() => {
    getCustomerApi();
  }, []);

  const getCustomerApi = async () => {
    try {
      setLoading(true);
      const response = await getCustomer({
        limit: pageSize,
        offset: (page - 1) * pageSize,
      });
      if(response)
        setProductsData(response);
      else
        setAlertShown(true);
      setLoading(false);
    } catch (err) {
      setAlertShown(true);
      setLoading(false);
      console.log("Error fetching customer:", err);
    }
  };

  const handleEditRowBtn = (index) => {
    saveIndex(index);
    navigate("/updateCustomer");
  };

  const handlefirstNameSort = () => {
    setfirst_nameSortOrder((prev) =>
      prev === "ascend" ? "descend" : "ascend"
    );
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredData = productsData.filter((item) => {
    if (searchTerm) {
      return (
        item.id.toString().includes(searchTerm) ||
        item.first_name.toLowerCase().includes(searchTerm) ||
        item.last_name.toLowerCase().includes(searchTerm) ||
        item.email.toLowerCase().includes(searchTerm) ||
        item.mobile.toString().includes(searchTerm)
      );
    }
    return true;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const first_nameA = (a.first_name || "").toUpperCase();
    const first_nameB = (b.first_name || "").toUpperCase();
    return first_nameSortOrder === "ascend"
      ? first_nameA.localeCompare(first_nameB)
      : first_nameB.localeCompare(first_nameA);
  });

  let columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "First Name", dataIndex: "first_name", key: "first_name" },
    { title: "Last Name", dataIndex: "last_name", key: "last_name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile", dataIndex: "mobile", key: "mobile" },
    { title: "Latitude", dataIndex: "latitude", key: "latitude" },
    { title: "Longitude", dataIndex: "longitude", key: "longitude" },
    { title: "Customer Name", dataIndex: "customer_name", key: "customer_name" },
    { title: "Street", dataIndex: "street", key: "street" },
    { title: "Landmark", dataIndex: "landmark", key: "landmark" },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Pincode", dataIndex: "pincode", key: "pincode" },
    { title: "Address Type", dataIndex: "addr_type", key: "addr_type" },
    { title: "Is Default", dataIndex: "isDefault", key: "isDefault" },
    { title: "House No", dataIndex: "house_no", key: "house_no" },
    { title: "Creation Date", dataIndex: "creation_date", key: "creation_date" },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <span>
          <Button icon={<BiEdit />} onClick={() => handleEditRowBtn(record.id)}>
            Edit
          </Button>
        </span>
      ),
    },
  ];

  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Update Customer')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 class="text-3xl text-[#03c9d7] mb-4">Customer Table</h1>
        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <Button onClick={handlefirstNameSort} className="ml-24">
            Sort Name
          </Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200, marginLeft: 1000 }}
          />
        </div>
        <Table
          dataSource={sortedData}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: (page, pageSize) => setPage(page),
            onShowSizeChange: (current, size) => setPageSize(size),
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            total: productsData.length,
          }}
          loading={loading}
          scroll={{ x: true }}
        />
      </div>
      <Modal
        title={message.title}
        visible={alertShown}
        footer={[
          <Button
            key="enter"
            className="bg-[#03c9d7]"
            type="primary"
            onClick={() => {
              navigate("/Customers");
              setAlertShown(false);
            }}
          >
            OK
          </Button>,
        ]}
        closable={false}
        className="error-modal"
      >
        <p>{message.message}</p>
      </Modal>
    </div>
  );
}
