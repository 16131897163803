import React, { useState } from "react";
import { Input, Button, Modal } from "antd";
import { useApiContext } from "../contexts/ApiProvider";

function AddRoleForm({ handleTabClick }) {
  const { addRole } = useApiContext();
  const [formData, setFormData] = useState({
    role: "",
    permissions: [],
  });
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [message, setMessage] = useState({
    title: "Error",
    message: "Error adding new role",
  });

  const permissionSelect = [
    "View Admin ",
    "Update Admin ",
    "View Products ",
    "Update Products ",
     "View DeliveryMan ",
     "Update DeliveryMan ",
     "View Orders ",
     "Update Orders ",
     "View Employees ",
     "Update Employees ",
     "View Customers ",
     "Update Customers ",
     "View Cart ",
     "Update Cart ",
     "View Stores ",
     "Update Stores ",
     "View Coupons ",
     "Update Coupons ",
     "View Banners ",
     "Update Banners ",
     "View Brands ",
     "Update Brands ",
     "View Contact ",
     "Update Contact ",
     "View Inventory ",
     "Update Inventory ",
     "View Roles ",
     "Update Roles "
  ];

  const handlePermissionChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      permissions: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await addRole(formData);
      setLoading(false);
      if (!response || response.errors) {
        setAlertShown(true);
        console.log("Something went wrong", response);
        return;
      }
      setMessage({ title: "Success", message: "Successfully added role" });
      setAlertShown(true);
    } catch (err) {
      setAlertShown(true);
      console.log("Some error occurred", err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className="rounded-md text-center">
          <div className="bg-[#007F73] border-2 font-sans shadow-md h-32">
            <h1 className="text-5xl text-white leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white dark:text-black">
              Add Role
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="flex flex-col items-start">
                <label
                  htmlFor="role"
                  className="text-sm font-medium text-black mt-5"
                >
                  Role:
                </label>
                <Input
                  id="role"
                  className="h-[50px] w-[1000px] mt-5 border-white text-black"
                  value={formData.role}
                  onChange={handleChange}
                  name="role"
                  placeholder="Enter Role"
                />
              </div>
              <div className="flex flex-col items-start mt-5">
                <label
                  htmlFor="permissions"
                  className="text-sm font-medium text-black"
                >
                  Permissions:
                </label>
                {permissionSelect.map((permission, index) => (
                  <div key={index} className="mt-2">
                    <input
                      type="checkbox"
                      id={`permission_${index}`}
                      value={permission}
                      onChange={(e) => {
                        const checkedPermissions = e.target.checked
                          ? [...formData.permissions, e.target.value]
                          : formData.permissions.filter(
                              (permission) => permission !== e.target.value
                            );
                        handlePermissionChange(checkedPermissions);
                      }}
                    />
                    <label htmlFor={`permission_${index}`}>
                      {permission}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex justify-center mt-5">
                <Button
                  type="primary"
                  className="bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddRoleForm;
