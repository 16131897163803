import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import { ApiContext, useApiContext } from "../contexts/ApiProvider";
import { selected } from "@syncfusion/ej2/pivotview";
import {
  storesAddEditField,
  couponsAddField,
  is_for_new_user,
  storestatuses,
} from "../data/dummy";
import { Input, Select, Button } from "antd";
import { Modal } from "antd";
const { Option } = Select;

function AddStoreForm({handleTabClick}) {
  // State variables for form fields
  const initialData = {
    statusIn: "",
    inputFieldValIn: "",
  };

  const [stores, setStores] = useState([]);

  const [status, setStatus] = useState(initialData.statusIn);
  const { addStore } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);
  const [loading, setLoading] = useState(false);
 const [message, setMessage] = useState({title:"Error",message:"Error adding new Store "});
  const [formData, setFormData] = useState({
    name: "",
    latitude: 45.4545,
    longitude: 34.3434,
    street: "",
    city: "",
    state: "",
    pin: "",
    phone: "",
    adminId: "1",
  });
  const handleEditInputChange = (val, editFieldName) => {
    // console.log(editFieldName)
    //console.log(val)
    setFormData({ ...formData, [editFieldName]: val });
    //console.log(formData)
  };
  const [suggestTags, setsuggestTags] = useState([]);
  const fileInputRef = useRef(null);

  // useEffect(() => {
  //   // const fetchData = async () => {
  //   //   try {
  //   //     const categories = await getCategories();
  //   //     const subcategories = await getSubCategories();
  //   //     const brand = await getstores();
  //   //     setCategories(categories);
  //   //     setSubCategories(subcategories);
  //   //     setsuggestTags(subcategories.map(sub => sub.subcategory))
  //   //     setStores(brand);
  //   //   } catch (error) {
  //   //     console.error("Error:", error);
  //   //   }
  //   // };

  //   // fetchData();
  // });

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
      setLoading(true)
      const response = await addStore(formData);
      setLoading(false)
      if (!response||response?.errors) {
        setAlertShown(true)
        console.log("Something went wrong", response);
        return;
      }
      setMessage({title:"Success",message:"Successfully added store"})
      setAlertShown(true)
    } catch (err) {
      setAlertShown(true)
      console.log("Some error occured  ", err);
    }
  };

  const handleSetStatus = (val) => {
    //console.log(val)
    setStatus(val === "Active" ? "Active" : "Inactive");
    setFormData({ ...formData, status: val });
    //4console.log(inputFieldVal)
  };

  const handleSetIsForNewUser = (val) => {
    //console.log(val)
    formData.isForNewUserOnly = val === "Y" ? "Y" : "N";
    setFormData({ ...formData, isForNewUserOnly: val });
    //4console.log(inputFieldVal)
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // // If the name is brandName, update both brandName and brandId
    // if (name === "brandName") {
    //   const selectedBrand = stores.find((brand) => brand.brand_name === value);
    //   const brandId = selectedBrand ? selectedBrand.brand_id : "";
    //   const logo = selectedBrand ? selectedBrand.logo : "";
    //   const brandDescription = selectedBrand ? selectedBrand.brand_desc : ";";
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     brandName: value,
    //     brandId: brandId,
    //     logo: logo,
    //     brandDescription: brandDescription,
    //   }));
    // } else {
    // For other fields, just update the form data as usual
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center w-52 rounded-md ml-14 sm:w-auto ml-0 md:w-auto mb-24 mt-10 mx-auto h-fit">
        <div className=" rounded-md text-center ">
          <div className=" bg-[#007F73] border-2 font-sans  shadow-md h-32">
            <h1 className=" text-5xl text-white  leading-tight tracking-tight text-gray-900 text-start ml-6 mt-12 md:text-white  dark:text-black">
              Add Store
            </h1>
          </div>
          <div className="p-6 space-y-4 bg-white border-2 bg-gray-200 border-gray-300 shadow-md md:space-y-6 sm:p-8">
            <form className="flex flex-col p-5" onSubmit={handleSubmit}>
              <div className="product-info flex flex-col flex-wrap justify-between">
                {storesAddEditField.map((field, index) => (
                  <div key={index} className="flex flex-col items-start">
                    <label
                      htmlFor={field.label}
                      className="text-sm font-medium text-black mt-5"
                    >
                      {field.label}:
                    </label>
                    <Input
                      id={field.value}
                      className="h-[50px] w-[1000px] mt-5 border-white text-black"
                      value={formData[field.value]}
                      onChange={(e) =>
                        handleEditInputChange(e.target.value, field.value)
                      }
                      placeholder={`Enter Your ${field.label}`}
                    />
                  </div>
                ))}
                <div className="items-start justify-start flex flex-col mt-5">
                  <label>Status:</label>
                  <Select
                    placeholder="Select Status"
                    className="w-96 text-start md:h-[50px] w-96 text-start bg-white text-black mt-5"
                    value={status}
                    onChange={handleSetStatus}
                  >
                    {storestatuses.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex justify-center space-x-4 mt-5">
                <Button
                  type="primary"
                  className="items-center justify-center bg-[#007F73] w-52 h-11 border-gray-400 text-white font-semibold py-2 px-6 ml-4 hover:border-transparent border-none rounded-none"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title={message.title}
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                handleTabClick(0);
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>{message.message}</p>
        </Modal>
      </div>
    </section>
  );
}

export default AddStoreForm;
