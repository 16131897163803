import React from 'react';
import '../styling/footer.css'

const Footer = () => (
  <div className="footer fixed-bottom">
    <p className="dark:text-gray-200 text-gray-700 text-center m-20">
    </p>
    <p className="dark:text-gray-200 text-gray-700 text-center m-20">
      © 2023 All rights reserved by Paaniwala.com
    </p>
  </div>
);

export default Footer;