import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button, Select, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { Header } from "./index.js";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useStateContext } from "../contexts/ContextProvider.jsx";
import "../styling/Table.css";
import { endAdd } from "@syncfusion/ej2/grids.js";
import { Modal } from "antd";

const { Option } = Select;
const { Search } = Input;

export default function DeliveryManTable({ onAddDm }) {
  const navigate = useNavigate();
  const { saveIndex } = useStateContext();
  const [DmData, setDmData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [statusFilter, setStatusFilter] = useState(null);
  const [nameSortOrder, setNameSortOrder] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedId, setSearchedId] = useState(null);
  const { getAllDm } = useApiContext();
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    const fetchDms = async () => {
      try {
        setLoading(true);
        const response = await getAllDm();
        setLoading(false);
        if(response)
        setDmData(response);
        else setAlertShown(true)
        
      } catch (error) {
        setLoading(false);
        setAlertShown(true)
        console.log("Error fetching admin data:", error);
      }
    };

    fetchDms();
  }, []);

  const handleExpand = (expanded, record) => {
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const handleEditRowBtn = (id) => {
    saveIndex(id);
    navigate("/updateDeliveryMan");
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const handleNameSort = () => {
    setNameSortOrder((prev) => (prev === "ascend" ? "descend" : "ascend"));
  };

  const handleSearchId = (value) => {
    const trimmedValue = value.trim();
    setSearchedId(trimmedValue);
  };

  const filteredData = DmData.filter((item) => {
    if (statusFilter && item.status !== statusFilter) return false;
    if (searchedId) {
      const searchLower = searchedId.toLowerCase();
      if (
        item.id.toString().includes(searchLower) ||
        item.name.toLowerCase().includes(searchLower) ||
        item.email.toLowerCase().includes(searchLower) ||
        item.mobile.toString().includes(searchLower)
      ) {
        return true;
      }
      return false;
    }
    return true;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameSortOrder === "ascend"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  let columns = [
    { title: "ID", dataIndex: "id" },
    { title: "Name", dataIndex: "name" },
    { title: "Aadhar ID", dataIndex: "aadhar_id" },
    { title: "Vehicle Number", dataIndex: "vehicle_no" },
    { title: "Mobile", dataIndex: "mobile" },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.id)}>Edit</Button>
      ),
    },
  ];

  //Check access for edit button
  const permissions= sessionStorage.getItem('permissions');
  if (!permissions.includes('Update DeliveryMan')) {
    columns = columns.filter(column => column.title !== "Actions");
  }

  const expandedRowRender = (record) => {
    const { key, ...rest } = record;
    const entries = Object.entries(rest);
    const quarterLength = Math.ceil(entries.length / 4);

    const firstColumnData = entries.slice(0, quarterLength);
    const secondColumnData = entries.slice(quarterLength, quarterLength * 2);
    const thirdColumnData = entries.slice(quarterLength * 2, quarterLength * 3);
    const fourthColumnData = entries.slice(quarterLength * 3);

    const renderColumnData = (columnData) => {
      const keyColumnWidth = 150;
      const valueColumnWidth = 200;

      return (
        <ul style={{ paddingLeft: 0, listStyle: "none", margin: 0 }}>
          {columnData.map(([key, value]) => (
            <li key={key} style={{ marginBottom: "8px", display: "flex" }}>
              <strong
                style={{
                  width: `${keyColumnWidth}px`,
                  flexShrink: 0,
                }}
              >
                {key}:
              </strong>{" "}
              <span
                style={{
                  maxWidth: `${valueColumnWidth}px`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </span>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: "#fafafa",
        }}
      >
        <div style={{ width: "25%" }}>{renderColumnData(firstColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(secondColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(thirdColumnData)}</div>
        <div style={{ width: "25%" }}>{renderColumnData(fourthColumnData)}</div>
      </div>
    );
  };

  return (
    <div className="flex">
      <div class="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 class="text-3xl text-[#03c9d7] mb-4">DeliveryMan Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <label className="text-[#03c9d7] text-xl">Select Status:</label>
          <Select
            style={{ width: 120, marginRight: "16px" }}
            placeholder="Filter Status"
            onChange={handleStatusFilter}
          >
            <Option value="">All</Option>
            <Option value="active">Active</Option>
            <Option value="pending">Pending</Option>
          </Select>
          <Button onClick={handleNameSort}>Sort Name</Button>
          <Search
            placeholder="Search here"
            onChange={(e) => handleSearchId(e.target.value)}
            style={{ width: 200, marginLeft: 800 }}
          />
        </div>
        <Table
          dataSource={
            searchedId
              ? DmData.filter((item) => {
                  const searchLower = searchedId.toLowerCase();
                  return (
                    item.id.toString().includes(searchLower) ||
                    item.name.toLowerCase().includes(searchLower) ||
                    item.email.toLowerCase().includes(searchLower) ||
                    item.mobile.toString().includes(searchLower)
                  );
                })
              : sortedData.map((item, index) => ({
                  ...item,
                  key: index,
                }))
          }
          columns={columns}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: handleExpand,
          }}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: (page, pageSize) => setPage(page),
            onShowSizeChange: (current, size) => setPageSize(size),
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            total: filteredData.length,
          }}
          loading={loading}
        />
      </div>
      <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/DeliveryMan");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
          className="error-modal"
        >
          <p>Error fetching Delivery Man data</p>
        </Modal>
    </div>
  );
}
