import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; // Import Navigate from react-router-dom
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import "./App.css";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Employees,
  Customers,
  Products,
  Stores,
  Coupons,
  Banners,
  Brands,
  Admin,
  Contact,
  Cart,
  Dm,
  Roles,
  Inventory,
} from "./pages";
import { useStateContext } from "./contexts/ContextProvider";
import SignIn from "./components/SignIn";
import { PrivateRoute } from "./components/PrivateRoute";
import UpdateProduct from "./pages/UpdateProduct";
import UpdateCoupons from "./components/UpdateCoupons";
import UpdateBrand from "./components/UpdateBrand";
import UpdateStore from "./components/UpdateStore";
import UpdateAdmin from "./components/UpdateAdmin";
import UpdateCustomer from "./components/UpdateCustomer";
import UpdateOrderForm from "./components/UpdateOrder";
import UpdateDmForm from "./components/UpdateDeliveryMan";
import DeliveryMan from "./pages/DeliveryMan";
import UpdateDeliveryManForm from "./components/UpdateDeliveryMan";
import UpdateContactForm from "./components/UpdateContactForm";
import UpdateBannerForm from "./components/UpdateBannerForm";
import UpdateInventory from "./components/UpdateInventory";
import UpdateRole from "./components/UpdateRole";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    userLoggedIn,
  } = useStateContext();

  const openUpdateProductPage = () => {
    window.open("/updateProduct", "_blank");
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="TopCenter">
              <button
                type="button"
                style={{ borderRadius: "50%", backgroundColor: currentColor }}
                className="text-3xl p-3 text-white hover:drop-shadow-xl hover:bg-light-gray"
                onClick={() => setThemeSettings(true)}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}

          <div
            className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full
              ${activeMenu ? "md:ml-72" : " flex-2"}
              `}
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full app-header-shadow">
              <Navbar />
            </div>

            <div>
              {themeSettings && <ThemeSettings />}
              <Routes>
                {/* <PrivateRoute path="/" element={<SignIn />} /> */}
                <Route
                  path="/"
                  element={!userLoggedIn ? <SignIn /> : <Products />}
                />
                <Route path="/signin" element={<SignIn />} />

                {/* dashboard  */}
                <Route
                  path="/ecommerce"
                  element={
                    <PrivateRoute>
                      <Ecommerce />
                    </PrivateRoute>
                  }
                />

                {/* pages  */}
                <Route
                  path="/Admin"
                  element={
                    <PrivateRoute>
                      <Admin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Roles"
                  element={
                    <PrivateRoute>
                      <Roles />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/DeliveryMan"
                  element={
                    <PrivateRoute>
                      <DeliveryMan />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Employees"
                  element={
                    <PrivateRoute>
                      <Employees />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Customers"
                  element={
                    <PrivateRoute>
                      <Customers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Cart"
                  element={
                    <PrivateRoute>
                      <Cart />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Products"
                  element={
                    <PrivateRoute>
                      <Products />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Stores"
                  element={
                    <PrivateRoute>
                      <Stores />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Coupons"
                  element={
                    <PrivateRoute>
                      <Coupons />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Inventory"
                  element={
                    <PrivateRoute>
                      <Inventory />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Banners"
                  element={
                    <PrivateRoute>
                      <Banners />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Brands"
                  element={
                    <PrivateRoute>
                      <Brands />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Contact"
                  element={
                    <PrivateRoute>
                      <Contact />
                    </PrivateRoute>
                  }
                />
                <Route path="/updateAdmin" element={<UpdateAdmin />} />
                <Route path="/updateOrder" element={<UpdateOrderForm />} />
                <Route path="/updateCustomer" element={<UpdateCustomer />} />
                <Route path="/updateProduct" element={<UpdateProduct />} />
                <Route path="/updateCoupons" element={<UpdateCoupons />} />
                <Route path="/updateBrand" element={<UpdateBrand />} />
                <Route path="/updateBannerForm" element={<UpdateBannerForm />} />
                <Route path="/updateInventory" element={<UpdateInventory />} />
                <Route path="/updateRole" element={<UpdateRole />} />
                <Route
                  path="/updateContact"
                  element={<UpdateContactForm />}
                ></Route>
                <Route path="/updateStore" element={<UpdateStore />} />
                <Route
                  path="/updateDeliveryMan"
                  element={<UpdateDeliveryManForm />}
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
