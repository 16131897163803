import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Select, Input } from "antd";
import { useApiContext } from "../contexts/ApiProvider.jsx";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

const { Option } = Select;
const { Search } = Input;

export default function RoleTable() {
  const navigate = useNavigate();
  const { saveIndex } = useStateContext();
  const { getRoles, updateRole } = useApiContext();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const rolesData = await getRoles();
        setRoles(rolesData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAlertShown(true);
        console.error("Error fetching roles data:", error);
      }
    };

    fetchRoles();
  }, []);

  const handleEditRowBtn = async (role) => {
    try {
      // Navigate to update role page with role id
      saveIndex(role);
      navigate("/updateRole", { state: { role } });

    } catch (error) {
      console.error("Error editing row:", error);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredRoles = roles.filter((role) => {
    if (searchTerm) {
      return role.role.toLowerCase().includes(searchTerm);
    }
    return true;
  });

  let columns = [
    { title: "Role", dataIndex: "role" },
    { title: "Permissions", dataIndex: "permissions" },
    {
      title: "Actions",
      render: (_, record) => (
        <Button onClick={() => handleEditRowBtn(record.role)}>Edit</Button>
      ),
    },
  ];

    //Check access for edit button
    const permissions= sessionStorage.getItem('permissions');
    if (permissions&&!permissions.includes('Update Roles')) {
      columns = columns.filter(column => column.title !== "Actions");
    }

  return (
    <div className="flex">
      <div className="w-full m-2 p-2 md:p-10 bg-white rounded-3xl shadow-md">
        <h1 className="text-3xl text-[#03c9d7] mb-4">Role Table</h1>

        <div style={{ marginBottom: "16px", marginTop: "25px" }}>
          <Search
            placeholder="Search role"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 200 }}
          />
        </div>

        <Table
          dataSource={filteredRoles}
          columns={columns}
          loading={loading}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />

        <Modal
          title="Error"
          open={alertShown}
          closable={false}
          footer={[
            <Button
              key="enter"
              className="bg-[#03c9d7]"
              type="primary"
              onClick={() => {
                navigate("/roles");
                setAlertShown(false);
              }}
            >
              OK
            </Button>,
          ]}
        >
          <p>Error fetching roles data</p>
        </Modal>
      </div>
    </div>
  );
}
