import React from "react";
import "../styling/header.css";

const Header = ({ category, title, className }) => {
  return (
    <div className={`mb-5 ${className}`}>
      {/* <p className="text-gray-400 uppercase">{category}</p> */}
      <p className="text-slate-900 text-3xl font-extrabold tracking-tight uppercase header-page">
        {title}
      </p>
    </div>
  );
};

export default Header;
